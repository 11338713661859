const en = {
    common: {
        back: "Back",
        exit: "Exit",
        share: "Share",
        close: "Close",
        cancel: "Cancel",
        search: "Search",
        continue: "Continue",
        skip: "Skip",
        skipStep: "Skip Step",
        complete: "Complete",
        checkout: "Checkout",
        viewDetails: "View Details",
        orderSummary: "Order Summary",
        startOver: "Start Over",
        bookSingle: "Book Single",
        bookTwin: "Book Twin",
        bookDouble: "Book Double",
        addRoom: "Add a Room",
        copyLink: "Copy Link",
        email: "Email",
        print: "Print",
        download: "Download",
        date: "Date",
        dates: "Dates",
        time: "Time",
        times: "Times",
        total: "Total",
        price: "Price",
        minutes: "mins",
        guests: "Guests",
        guest: "Guest",
        room: "Room",
        rooms: "Rooms",
        from: "From",
        to: "To",
        nights: "Nights",
        adults: "Adults",
        remove: "Remove",
        add: "Add",
        editBooking: "Edit Booking",
        booked: "Booked",
        location: "Location",
        experience: "Experience",
        treatment: "Treatment",
        updatingBooking: "updating booking...",
        termsAndConditions: "Terms & Conditions",
        perperson: "per person",
        perguest: "per guest",
        pay: "Pay",
        apply: "Apply",
        discount: "Discount",
        giftCards: "Gift Cards",
        loginToBook: "Login or register to book",
        yesPlease: "Yes, Please",
        noThanks: "No, Thanks",
        insurance: "Flex Insurance",
        upgradeNow: "Upgrade Now",
        spaAccess: "Spa Access on Arrival",
    },
    bookHome: {
        heading: "Your Booking Details",
        spa: "Which Desination?",
        experience: "Which experience?",
        dates: "Which dates?",
        locationPlaceholder: "Select a destination",
        experiencePlaceholder: "Select an experience",
        experienceLabel: "Select an experience",
        existingBookingTitle: "Hey There!",
        existingBookingMessage:
            "It looks like you already have a booking in the cart.",
    },
    cartItem: {
        inclusiveTreatmentsLabel: "Inclusive Treatments for Guest",
        inclusiveTreatmentsLabelSimple: "Inclusive Treatments",
        additionalsLabel: "Additional Treatments for Guest",
        additionalsLabelSimple: "Additional Treatments",
        finishingTouchesLabel: "Finishing Touches for Guest",
        finishingTouchesLabelSimple: "Finishing Touches",
        shippingLabel: "Shipping",
        giftCardLabel: "Gift Card",
        giftCardSubLabel: "Your Gift",
        giftSetLabel: "Your Gift Set",
    },
    cartTreatmentItem: {
        categoryLabel: "category",
        daysLabel: "time of days",
    },
    countdownModel: {
        titleBefore: "Booking Time Remaining: ",
        popupTitle: "5 Minutes Remaining",
        popupTextBefore: "Your session will expire in: ",
    },
    headerBar: {
        exitPopupTitle: "Are you sure you want to leave?",
        exitPopupContinue: "Continue with booking",
        exitPopupLeave: "Yes, I'm sure",
        logoText: "Barons Eden",
        backText: "Back to",
    },
    imageGallery: {
        noImgText: "No image available",
        imageAltText: "Image of",
    },
    guestSelection: {
        placeholder: "Select number of guests",
        maxGuestsTitle: "Booking on behalf of a group?",
        maxGuestsMessage:
            "For groups over six, please call our Guest Experience Team on <a class='text-primary' href='tel:01608787986'>01608 787986</a>, who will be able to assist with your booking, or continue to proceed with your online booking for 6 or less guests.",
    },
    rateCalendar: {
        monday: "Mon",
        tuesday: "Tue",
        wednesday: "Wed",
        thursday: "Thu",
        friday: "Fri",
        saturday: "Sat",
        sunday: "Sun",
        totalText: "Total (per person)",
        selectDatesText: "Select Dates",
        confirmDatesText: "Confirm Dates",
        selectDateText: "Select Date",
        confirmDateText: "Confirm Date",
        loadingText: "Just a moment",
    },
    shareThis: {
        title: "Share Itinerary",
    },
    shoppingCart: {
        title: "Your booking",
        visitTitle: "",
    },
    signpost: {
        specialServiceNote: "Add a note to your booking",
    },
    timeSelector: {
        loadingText: "Just a moment",
    },
    treatmentSelection: {
        dateSelectLabel: "Treatment Date",
        dateSelect: "Select your preferred date",
        timeSelect: "Select your preferred time",
        addToBooking: "Add to Booking",
        checkAvailability: "Check Availability",
    },
    datePicker: {
        placeholder: "Select a date",
    },
    roomSelection: {
        title: "Rooms & Guests",
        placeholder: "Select rooms & guests",
    },
    user: {
        loginHeading: "Login or Sign up",
        loginSubHeading: "",
        loginHeadingBook: "Continue",
        loginSubHeadingBook: "with your booking",
        loginButton: "Continue with email",
        loginTerms: `By clicking continue with Google/Apple/Email, you agree to our <a href="https://baronseden.com/terms-and-conditions" target="_blank" class="text-primary">Terms & Conditions</a> and <a href="https://baronseden.com/privacy" target="_blank" class="text-primary">Privacy Policy</a>.`,
        dividerText: "OR",
        loadingText: "Just a moment",
        emailLabel: "Email Address",
        passwordLabel: "Password",
        passwordReset: "Forgot Password?",
        signUp: "Or Sign Up to continue",
    },
    treatments: {
        title: "Treatments",
        subTitle: "Select your treatments",
        upgrade:
            "<strong>Fancy an Upgrade?</strong> Discover our exclusive offers",
        noTreatments: "No treatments are currently available.",
    },
    giftcards: {
        title: "Gift Cards",
        subTitle: "Select your gift card",
        cartGiftCardLabel: "Your Gift Card",
        cartGiftCardTypeLabel: "Gift Card Type",
        giftsetLabel: "Gift Set",
    },
    additionalTreatments: {
        title: "Select Additional Treatments",
        subTitle: "",
    },
    pms: {
        noExperience: "No experiences available for your selected dates",
        noInclusives:
            "Your selected experience does not have any inclusive treatments",
        anotherDate: "Choose another date",
        loadingRates: "Fetching experiences...",
    },
    experience: {
        title: "Your Experience",
        subTitle: "Select your experience to continue",
        selectExperienceLabel: "Select experience",
        roomSelectLabel: "Select a Room",
        roomTitle: "Your Room",
        roomSubTitle: "Select your room for Room",
        loading: "Fetching experiences...",
    },
    extras: {
        title: "Extras",
        subTitle: "Enhance your stay",
        loadingText: "Just a moment",
    },
    inclusive: {
        title: "Select Inclusive Treatments",
        subTitle: "",
        maximumReached:
            "No more inclusive treatments are available for your experience, please remove one to add another",
    },
    upgrade: {
        title: "Fancy an Upgrade?",
        subTitle: "Elevate your treatment by upgrading with a discounted price",
    },
    reviewBooking: {
        heading: "Review Your Experience",
        detailsHeading: "Your Details",
        roomHeading: "Room Details",
        roomType: "Room Type",
        roomTreatments: "Inclusive Treatment",
        editTreatment: "Edit Treatment",
        removeTreatment: "Remove Treatment",
        roomAdditionals: "Additional Treatment",
        roomFinishingTouches: "Finishing Touches",
        editFinishingTouch: "Edit Finishing Touch",
        removeFinishingTouch: "Remove Finishing Touch",
        treamtmentHeading: "Treatment Details",
        treatmentStartTime: "Start Time",
        confirmTitle: "Woah..",
        confirmMessage: "Are you sure you want to remove this from the basket?",
        confirmExperienceRemovalMessage:
            "Are you sure you want to remove this experience from the basket? All associated treatments and extras will be removed.",
    },
    bookingConfirmation: {
        heading: "Confirmation of Your Booking",
        thankYouHeading: "Thank you for your booking,",
        thankYouSubHeading:
            "We look forward to welcoming you through our gran Hall doors very soon.",
        orderConfirmationText:
            "A conforming email will arrive in your inbox shortly. followed by a Pre Arival consultation from where we can get fully aquainted with your dietary requirements and health status, ensuring every second of your experience is tailored to your needs.",
        orderConfirmationExplore:
            "In the meantime, all thats left to do peruse our historic property, discovering every treaure the Hall has to offer.",
        HCHLinkText: "Hoar Cross Hall",
        EHLinkText: "Eden Hall",
        yourItinary: "Your Itinary",
        bookingReference: "Booking Reference",
    },
    orderHistory: {
        heading: "My Orders",
        loading: "Loading previous orders",
    },
    editProfile: {
        heading: "My Profile",
        loading: "Saving profile...",
    },
    editAddress: {
        heading: "My Address",
        loading: "Saving address...",
    },
    editAccess: {
        heading: "Username / Password",
        loading: "Updating password",
    },
    account: {
        navProfile: "Profile",
        navAddress: "Address",
        navSecurity: "Security",
        navOrders: "Orders",
        navMembership: "Membership",
    },
    passwordReset: {
        heading: "Request a New Password",
        updateHeading: "Update your Password",
        text: "Fill in the form below to request a password reset link.",
        updateText: "Fill in the form below to update your password",
        loading: "sending password reset email",
    },
    checkout: {
        heading: "Checkout",
        subHeading: "Checkout Securely",
        termsLabel:
            "By clicking below, I agree and consent to the <a href='https://baronseden.com/terms-conditions' target='_blank' class='text-primary'>Terms & Conditions</a> as well as the collection, use and disclosure of my personal data in accordance with the <a href='https://baronseden.com/privacy' target='_blank' class='text-primary'>Privacy Policy</a>.",
        loadingMessage: "Loading our payment provider please wait...",
        processingMessage:
            "We are finialising the transaction, do not close the window",
        promoHeading: "Redeem Promo Code",
        giftCardHeading: "Redeem Gift Card",
        flexTitle: "Flex your booking for",
    },
    error: {
        heading: "Page not found!",
        subheading: "Sorry, the page you are looking for does not exist.",
        message: "Please check the URL or click the button below to return.",
        button: "Return to Homepage",
    },
    rooms: {
        loading: "Fetching rooms...",
    },
    afternoonTea: {
        title: "Afternoon Tea",
        subTitle: "Select your afternoon tea",
        promotionNote: "Please add any special instructions",
        promotionNotePlaceholder: "Enter any special requests here",
        noAvailability: "No availability for your selected dates",
        anotherDate: "Choose another date",
        cartPromotionName: "Your Promotion",
        selectTime: "Select your preferred time",
        loadingText: "Just a moment",
        cartPromotionPrice: "Price Per Person",
        accountRequired:
            "You need to be logged in to make an afternoon tea reservation",
    },
    prearrival: {
        title: "Your Pre-arrival Consultation",
        subTitle: "Let us tailor your experience",
        guestSubTitle: "It's all about you",
        pregnantTitle: "Are you currently pregnant?",
        completed:
            "Pre-arrival consultation has already completed for this booking, consultions must be completed individually for each guest.",
        notFound:
            "No pre-arrival consultation found for this booking reference.",
    },
};

export default en;
