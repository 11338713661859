import { reactive } from "vue";
import { defineStore } from "pinia";
import type { Profile, Error } from "@/types";
import api from "@/api/axios";

interface ProfileState {
    isLoading: boolean;
    profile: Profile | null;
    error: Error;
}

const initialState = (): ProfileState => ({
    isLoading: true,
    profile: null,
    error: {
        hasError: false,
        message: "",
    },
});

export const useProfileStore = defineStore(
    "profile",
    () => {
        const state = reactive<ProfileState>(initialState());

        async function getProfile(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.get("api/account/profile");
                state.profile = response.data.data;

                return true;
            } catch (errorResponse: any) {
                if (
                    errorResponse.response &&
                    errorResponse.response.status === 401
                ) {
                    console.log(
                        "User is not authenticated. Handling 401 error."
                    );
                    return false;
                } else {
                    state.error.hasError = true;
                    state.error.message =
                        errorResponse.response?.data?.message ||
                        "An error occurred.";
                    return false;
                }
            } finally {
                state.isLoading = false;
            }
        }

        async function updateProfile(): Promise<boolean> {
            state.isLoading = true;

            try {
                await api.post("api/account/profile", state.profile);

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        const $reset = () => {
            Object.assign(state, initialState());
        };

        return {
            state,
            getProfile,
            updateProfile,
            $reset,
        };
    },
    {
        persist: {
            storage: sessionStorage,
        },
    }
);
