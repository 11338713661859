<template>
    <div :class="`flex flex-col items-start items-${align}`">
        <div class="flex gap-1 items-baseline">
            <span
                v-if="priceText"
                class="font-subheading font-bold text-black-900 text-xs uppercase"
            >
                {{ priceText }}
            </span>
            <span
                :class="`font-subheading font-bold leading-6 text-black-900 ${size}`"
                aria-live="polite"
                v-if="price"
            >
                <span class="sr-only">Price:</span>

                {{
                    Number(price) === 0
                        ? "FREE"
                        : Number(price) % 1 === 0
                        ? "£" + Number(price).toFixed(0)
                        : "£" + Number(price).toFixed(2)
                }}
            </span>
            <span
                v-if="discountPrice"
                class="text-sm text-danger-900 line-through ml-2"
                aria-live="polite"
            >
                <span class="sr-only">Discounted price: </span>
                £{{
                    discountPrice % 1 === 0
                        ? discountPrice.toFixed(0)
                        : discountPrice
                }}
            </span>
        </div>
        <div
            v-if="computedText"
            class="text-xxs uppercase font-subheading font-bold text-black-600/50 w-max"
        >
            {{ computedText }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
    price: number | null;
    discountPrice?: number | null;
    priceText?: string | null;
    guests?: number | null;
    nights?: number | null;
    text?: string | null;
    size?: string;
    align?: "center" | "start" | "end";
}

const props = defineProps<Props>();
const align = props.align ?? "start";
const size = props.size ?? "text-[24px]";

const computedText = computed(() => {
    if (props.text) {
        return props.text;
    }

    const { guests, nights } = props;

    if (!guests || !nights) {
        return "";
    }

    if (props.guests === 1 && props.nights === 1) {
        return "Per guest, per night";
    }
    const guestText = props.guests === 1 ? "Guest" : "Guests";
    const nightText = props.nights === 1 ? "Night" : "Nights";

    return `${props.guests} ${guestText}, ${props.nights} ${nightText}`;
});
</script>

<style scoped lang="scss">
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
</style>
