<template>
    <div class="guest-selector flex flex-col gap-2 flex-auto">
        <div
            class="guest-selector-heading font-subheading font-extrabold text-black-600 text-sm"
        >
            {{ $t("common.guests") }}
        </div>
        <div class="relative">
            <input
                type="text"
                :value="summary"
                :placeholder="$t('guestSelection.placeholder')"
                class="guest-selector-overview font-body w-full bg-selectBG border border-stone-900 px-4 py-1 leading-10 text-sm rounded-md"
                readonly
                aria-readonly="true"
            />
            <div
                class="guest-selector-inline absolute right-4 top-1/2 -translate-y-1/2 justify-between items-center"
            >
                <div class="flex items-center space-x-2">
                    <button
                        @click="decrement"
                        :disabled="guests.length === 1"
                        class="guest-selector-decrease w-6 h-6 bg-primary text-ivory-900 flex items-center justify-center disabled:opacity-50 rounded-sm"
                        aria-label="Decrease guests"
                    >
                        <MinusIcon />
                    </button>
                    <span
                        class="guest-selector-count font-body font-sm font-light"
                    >
                        {{ guests.length }}
                    </span>
                    <button
                        @click="increment"
                        :disabled="guests.length >= maxAdults"
                        class="guest-selector-increase w-6 h-6 bg-primary text-ivory-900 flex items-center justify-center disabled:opacity-50 rounded-sm"
                        aria-label="Increase guests"
                    >
                        <PlusIcon />
                    </button>
                </div>
            </div>
        </div>
        <PopupModel
            :visible="modalVisible"
            :title="$t('guestSelection.maxGuestsTitle')"
            @close="modalVisible = false"
        >
            {{ $t("guestSelection.maxGuestsMessage") }}
            <template #buttons>
                <DynamicButton
                    variant="solid"
                    @click="modalVisible = false"
                    additionalClasses="mt-4"
                    aria-label="Close guest selection modal"
                >
                    {{ $t("common.close") }}
                </DynamicButton>
            </template>
        </PopupModel>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, reactive } from "vue";
import PopupModel from "@/components/global/PopupModel.vue";
import DynamicButton from "@/components/forms/DynamicButton.vue";
import GroupIcon from "@/components/icons/GroupIcon.vue";
import MinusIcon from "@/components/icons/MinusIcon.vue";
import PlusIcon from "@/components/icons/PlusIcon.vue";

interface Props {
    modelValue?: { adults: number }[] | number;
    maxAdults: number;
    mode?: string;
}

const props = defineProps<Props>();

const maxAdults = props.maxAdults || 6;
const mode = props.mode || "pms";

const emit = defineEmits(["update:modelValue"]);

const modalVisible = ref(false);
const guests = reactive(
    mode === "pms"
        ? (props.modelValue as { adults: number }[]) || [{ adults: 1 }]
        : Array((props.modelValue as number) || 1).fill({ adults: 1 })
);

const summary = computed(() => {
    const guestLabel = guests.length === 1 ? "guest" : "guests";
    return `${guests.length} ${guestLabel}`;
});

const increment = () => {
    if (guests.length < maxAdults) {
        guests.push({ adults: 1 });
        checkTotalGuests();
    }
};

const decrement = (index: number) => {
    if (guests.length > 1) {
        guests.splice(index, 1);
    }
};

const checkTotalGuests = () => {
    if (guests.length >= 6) {
        showModal();
    }
};

watch(
    guests,
    () => {
        const updatedValue = mode === "pms" ? guests : guests.length;
        emit("update:modelValue", updatedValue);
    },
    { deep: true }
);

const showModal = () => {
    modalVisible.value = true;
};
</script>

<style scoped lang="scss">
.guest-selector-inline {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.guest-selector-inline-heading {
    margin-bottom: 8px;
}
.guest-selector-increase,
.guest-selector-decrease {
    svg {
        @apply w-3;
    }
}
</style>
