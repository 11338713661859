<template>
    <div
        v-if="background"
        :class="computedClasses"
        :title="altText"
        :style="{ backgroundImage: `url(${imageSrc})` }"
        role="img"
        :aria-label="altText"
    ></div>
    <img
        v-else
        :src="imageSrc"
        :alt="altText"
        :title="altText"
        :class="computedClasses"
        loading="lazy"
    />
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
    src?: string | (() => string);
    alt?: string;
    background?: boolean;
    variant?: "basic" | "cover";
}

const props = defineProps({
    src: { type: [String, Function], default: "" },
    alt: { type: String, default: "Image description" },
    background: { type: Boolean, default: false },
    variant: { type: String, default: "basic" },
});

const variant = props.variant || "basic";

const imageSrc = computed(() => {
    return typeof props.src === "function" ? props.src() : props.src;
});

const altText = computed(() => {
    return props.alt || "Image description";
});

const computedClasses = computed(() => {
    const baseClass = "bg-cover bg-center w-full";

    let variantClasses: Record<string, string>;

    variantClasses = {
        basic: `h-auto`,
        cover: `h-full`,
        thumb: `h-64`,
    };

    return `${baseClass} ${variantClasses[variant]}`;
});
</script>

<style scoped lang="scss"></style>
