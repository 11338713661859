<template>
    <div class="cart-item p-6 flex flex-col bg-cart">
        <div
            class="flex justify-between items-center mb-2 py-2 border-b border-stone-100"
        >
            <h2
                class="font-subheading font-bold uppercase text-[12px]"
                role="heading"
                aria-level="2"
            >
                {{ $t("cartItem.giftCardLabel") }}
            </h2>

            <p
                class="font-subheading text-[16px] font-semibold"
                aria-live="polite"
            >
                £{{ item.total }}
            </p>
        </div>

        <p class="font-subheading font-bold uppercase text-sm mt-4 mb-2">
            {{ $t("cartItem.giftCardSubLabel") }}
        </p>

        <div class="flex justify-between items-center mb-4 text-sm w-full">
            <ul class="w-full">
                <li class="flex justify-between gap-2" role="listitem">
                    <span class="flex-auto text-[14px] tracking-tight">
                        {{ formatTitle(item.gift_card.name, 20) }}
                    </span>
                    <span class="text-[14px] font-medium font-subheading"
                        >£{{
                            Number(item.total) - Number(item.shipping.price)
                        }}</span
                    >
                </li>
            </ul>
        </div>

        <div
            v-if="
                item.shipping.shipping_id === 103014 ||
                item.shipping.shipping_id === 103016
            "
        >
            <p class="font-subheading font-bold uppercase text-sm mb-2">
                {{ $t("cartItem.giftSetLabel") }}
            </p>

            <div class="flex justify-between items-center mb-4 text-sm w-full">
                <ul class="w-full">
                    <li class="flex justify-between gap-2" role="listitem">
                        <span class="flex-auto text-[14px] tracking-tight">
                            {{ formatTitle(item.shipping.name, 20) }}
                        </span>
                        <span class="text-[14px] font-medium font-subheading"
                            >£{{ item.shipping.price }}</span
                        >
                    </li>
                </ul>
            </div>

            <p class="font-subheading font-bold uppercase text-sm mb-2">
                {{ $t("cartItem.shippingLabel") }}
            </p>

            <div class="flex justify-between items-center mb-4 text-sm w-full">
                <ul class="w-full">
                    <li class="flex justify-between gap-2" role="listitem">
                        <span class="flex-auto text-[14px] tracking-tight">
                            1st Class Post
                        </span>

                        <span class="text-[14px] font-medium font-subheading"
                            >FREE</span
                        >
                    </li>
                </ul>
            </div>
        </div>

        <div
            v-if="
                item.shipping.shipping_id !== 103014 &&
                item.shipping.shipping_id !== 103016
            "
        >
            <p class="font-subheading font-bold uppercase text-sm mb-2">
                {{ $t("cartItem.shippingLabel") }}
            </p>

            <div class="flex justify-between items-center mb-4 text-sm w-full">
                <ul class="w-full">
                    <li class="flex justify-between gap-2" role="listitem">
                        <span class="flex-auto text-[14px] tracking-tight">
                            {{ formatTitle(item.shipping.name, 20) }}
                        </span>
                        <span class="text-[14px] font-medium font-subheading"
                            >£{{ item.shipping.price }}</span
                        >
                    </li>
                </ul>
            </div>
        </div>

        <DynamicButton
            @click="removeGiftCard(item)"
            variant="text"
            size="none"
            aria-label="Remove retail item"
        >
            <span class="text-danger-900 text-xxs">
                {{ $t("common.remove") }}
            </span>
        </DynamicButton>
    </div>

    <PopupModel
        :visible="confirmationModalVisible"
        :title="$t('reviewBooking.confirmTitle')"
        @close="confirmationModalVisible = false"
    >
        {{ $t("reviewBooking.confirmMessage") }}
        <template #buttons>
            <DynamicButton
                variant="solid"
                @click="confirmRemoval(true)"
                additionalClasses="mt-4"
                aria-label="Close guest selection modal"
            >
                {{ $t("common.remove") }}
            </DynamicButton>
            <DynamicButton
                variant="outline"
                @click="confirmRemoval(false)"
                aria-label="Close guest selection modal"
            >
                {{ $t("common.cancel") }}
            </DynamicButton>
        </template>
    </PopupModel>

    <LoadingLogo
        v-if="isLoading"
        :loading="isLoading"
        :message="$t('common.updatingBooking')"
    />
</template>

<script setup lang="ts">
import { ref } from "vue";
import type { BookingRetailItem } from "@/types";
import { useRetailStore } from "@/stores/retail/retail";
import DynamicButton from "@/components/forms/DynamicButton.vue";
import PopupModel from "@/components/global/PopupModel.vue";
import LoadingLogo from "@/components/global/LoadingLogo.vue";
import { useBookingStepsStore } from "@/stores/booking/bookingSteps";
import { useBookingStore } from "@/stores/booking/booking";

interface Props {
    item: BookingRetailItem;
}

const props = defineProps<Props>();
const bookingStore = useBookingStore();
const bookingStepsStore = useBookingStepsStore();
const retailStore = useRetailStore();
const confirmationModalVisible = ref(false);
const resolveConfirmation = ref<((isConfirmed: boolean) => void) | null>(null);
const isLoading = ref(false);

const confirmRemoval = (isConfirmed: boolean) => {
    confirmationModalVisible.value = false;
    if (resolveConfirmation.value) {
        resolveConfirmation.value(isConfirmed);
    }
};

const showConfirmationModal = () => {
    confirmationModalVisible.value = true;

    return new Promise((resolve) => {
        resolveConfirmation.value = resolve;
    });
};

const formatTitle = (title: string, maxLength: number) => {
    if (!title) {
        return "";
    }

    return title.trim().length > maxLength
        ? title.trim().slice(0, maxLength) + "…"
        : title.trim();
};

const removeGiftCard = async (retailItem: BookingRetailItem) => {
    try {
        const isConfirmed = await showConfirmationModal();

        if (!isConfirmed) {
            return;
        }

        isLoading.value = true;

        await retailStore.removeGiftCard(
            retailItem.retail_folio_id,
            retailItem.retail_folio_item_id
        );

        if (bookingStore.state.booking.retail_items.length === 0) {
            await bookingStepsStore.resetToStep(1);
        }

        isLoading.value = false;
    } catch (error) {
        console.error("Error during treatment removal", error);

        throw error;
    }
};
</script>
