import { reactive } from "vue";
import { defineStore } from "pinia";
import type { Error } from "@/types/index";
import api from "@/api/axios";
import { useSystemStore } from "@/stores/system/system";
import { useBookingStore } from "@/stores/booking/booking";
import { useRouter } from "vue-router";
import { emitEvent } from "@/helpers/eventEmitter";
import { useUserStore } from "@/stores/auth/user";

interface CheckoutState {
    isLoading: boolean;
    transactionId: number | null;
    forwardUrl: string | null;
    threeDsEnabled: boolean;
    threeCSession: string | null;
    txid: string | null;
    authorisationCode: string | null;
    scaTransRef: string | null;
    cardholderName: string | null;
    address: string | null;
    merchantRef: string | null;
    amount: number | null;
    error: Error;
}

const initialState = (): CheckoutState => ({
    isLoading: false,
    transactionId: null,
    forwardUrl: null,
    amount: null,
    threeDsEnabled: false,
    threeCSession: null,
    txid: null,
    authorisationCode: null,
    scaTransRef: null,
    cardholderName: null,
    address: null,
    merchantRef: null,
    error: {
        hasError: false,
        message: "",
    },
});

export const useCheckoutStore = defineStore(
    "checkout",
    () => {
        const state = reactive<CheckoutState>(initialState());
        const systemStore = useSystemStore();
        const bookingStore = useBookingStore();
        const userStore = useUserStore();
        const router = useRouter();

        async function setCheckout(force: boolean): Promise<boolean> {
            state.isLoading = true;

            try {
                if (!bookingStore.state.booking.id) {
                    throw new Error("No booking ID found.");
                }

                const response = await api.post("api/checkout/start", {
                    booking_id: bookingStore.state.booking.id,
                    webfolio_id: systemStore.state.webfolioId,
                    session_id: systemStore.state.sessionId,
                    force: force
                });

                state.transactionId = response.data.data.transaction_id;
                state.forwardUrl = response.data.data.forward_url;
                state.amount = response.data.data.amount;
                state.threeDsEnabled = response.data.data.three_ds_enabled;
                state.threeCSession = response.data.data.three_c_session;

                if (response.data.checkout_completed) {
                    if(bookingStore.isPmsBooking()) {
                        bookingStore.state.orderReference = bookingStore.state.booking.pms_items[0].pms_folio_id;
                    } else if (bookingStore.state.booking.experience === "treatments") {
                        bookingStore.state.orderReference = bookingStore.state.booking.treatment_items[0].spa_folio_id;
                    } else if (bookingStore.state.booking.experience === "gift_cards") {
                        bookingStore.state.orderReference = bookingStore.state.booking.retail_items[0].retail_folio_id;
                    } else if (bookingStore.state.booking.experience === "dining") {
                        bookingStore.state.orderReference = bookingStore.state.booking.dining_items[0].retail_folio_id;
                    }
                    
                    bookingStore.state.booking.status = "complete";

                    if (response.data.analytics) {
                        emitEvent('analytics-event', {
                            type: 'purchase',
                            venue: bookingStore.state.booking.venue_id === 100 ? 'Hoar Cross Hall' : 'Eden Hall',
                            ecommerce: {
                                purchase: {
                                    actionField: {
                                        id: systemStore.state.webfolioId,
                                        revenue: bookingStore.state.booking.total,
                                        currency: 'GBP',
                                        promoCode: bookingStore.state.booking.promo_items.length > 0 ? bookingStore.state.booking.promo_items[0].promo_code : null,
                                        customerName: userStore.state.user?.first_name + '' + userStore.state.user?.last_name,
                                        customerEmail: userStore.state.user?.email,
                                    },
                                    products: response.data.analytics
                                }
                            }
                        });

                        emitEvent('analytics-event', {
                            type: 'checkout_success',
                            venue: bookingStore.state.booking.venue_id === 100 ? 'Hoar Cross Hall' : 'Eden Hall',
                            ecommerce: {
                                purchase: {
                                    actionField: {
                                        id: systemStore.state.webfolioId,
                                        revenue: bookingStore.state.booking.total,
                                        currency: 'GBP',
                                        promoCode: bookingStore.state.booking.promo_items.length > 0 ? bookingStore.state.booking.promo_items[0].promo_code : null,
                                        customerName: userStore.state.user?.first_name + '' + userStore.state.user?.last_name,
                                        customerEmail: userStore.state.user?.email,
                                    },
                                    products: response.data.analytics
                                }
                            }
                        });
                    }

                    router.push("/experience/confirmation");
                }

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function doCheckout(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/checkout/complete", {
                    transaction_id: state.transactionId,
                    booking_id: bookingStore.state.booking.id,
                    webfolio_id: systemStore.state.webfolioId,
                    session_id: systemStore.state.sessionId,
                    txid: state.txid,
                    authorisation_code: state.authorisationCode,
                    sca_trans_ref: state.scaTransRef,
                    cardholder_name: state.cardholderName,
                    address: state.address,
                    merchant_ref: state.merchantRef,
                });

                if(response) {
                    if(bookingStore.isPmsBooking()) {
                        bookingStore.state.orderReference = bookingStore.state.booking.pms_items[0].pms_folio_id;
                    } else if (bookingStore.state.booking.experience === "treatments") {
                        bookingStore.state.orderReference = bookingStore.state.booking.treatment_items[0].spa_folio_id;
                    } else if (bookingStore.state.booking.experience === "gift_cards") {
                        bookingStore.state.orderReference = bookingStore.state.booking.retail_items[0].retail_folio_id;
                    } else if (bookingStore.state.booking.experience === "dining") {
                        bookingStore.state.orderReference = bookingStore.state.booking.dining_items[0].retail_folio_id;
                    }

                    bookingStore.state.booking.status = "complete";

                    if (response.data.analytics) {
                        emitEvent('analytics-event', {
                            type: 'purchase',
                            venue: bookingStore.state.booking.venue_id === 100 ? 'Hoar Cross Hall' : 'Eden Hall',
                            ecommerce: {
                                purchase: {
                                    actionField: {
                                        id: systemStore.state.webfolioId,
                                        revenue: bookingStore.state.booking.total,
                                        currency: 'GBP',
                                        promoCode: bookingStore.state.booking.promo_items.length > 0 ? bookingStore.state.booking.promo_items[0].promo_code : null,
                                        customerName: userStore.state.user?.first_name + '' + userStore.state.user?.last_name,
                                        customerEmail: userStore.state.user?.email,
                                    },
                                    products: response.data.analytics
                                }
                            }
                        });

                        emitEvent('analytics-event', {
                            type: 'checkout_success',
                            venue: bookingStore.state.booking.venue_id === 100 ? 'Hoar Cross Hall' : 'Eden Hall',
                            ecommerce: {
                                purchase: {
                                    actionField: {
                                        id: systemStore.state.webfolioId,
                                        revenue: bookingStore.state.booking.total,
                                        currency: 'GBP',
                                        promoCode: bookingStore.state.booking.promo_items.length > 0 ? bookingStore.state.booking.promo_items[0].promo_code : null,
                                        customerName: userStore.state.user?.first_name + '' + userStore.state.user?.last_name,
                                        customerEmail: userStore.state.user?.email,
                                    },
                                    products: response.data.analytics
                                }
                            }
                        });
                    }

                    return true;
                } else {
                    return false;
                }
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        const $reset = () => {
            Object.assign(state, initialState());
        };

        return {
            state,
            $reset,
            setCheckout,
            doCheckout
        };
    }
);
