import { reactive } from "vue";
import { defineStore } from "pinia";
import type { User, Error, UserForm, CompleteRegistrationForm } from "@/types";
import api from "@/api/axios";
import { useSystemStore } from "@/stores/system/system";
import { usePreArrivalStore } from "@/stores/preArrival/preArrival";
import { useBookingStore } from "@/stores/booking/booking";
import { emitEvent } from "@/helpers/eventEmitter";
interface UserState {
    isAuthenticated: boolean;
    isTempUser: boolean;
    isPreArrivalUser: boolean;
    preArrivalRef: string | null;
    isLoading: boolean;
    user: User | null;
    error: Error;
    formErrors: FormErrors;
}

export interface FormErrors {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    password_confirmation: string
    gender: string;
    address: {
        address_1: string;
        address_2: string;
        city: string;
        county: string;
        country: string;
        post_code: string;
    };
    phone: string;
    mobile: string;
    date_of_birth: string;
    marketing_email: string;
    marketing_sms: string;
    marketing_mail: string;
    marketing_call: string;
    guest_booking_reference: string;
}

const initialState = (): UserState => ({
    isAuthenticated: false,
    isTempUser: false,
    isPreArrivalUser: false,
    preArrivalRef: null,
    isLoading: false,
    user: null,
    error: {
        hasError: false,
        message: "",
    },
    formErrors: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        gender: "",
        address: {
            address_1: "",
            address_2: "",
            city: "",
            county: "",
            country: "",
            post_code: "",
        },
        phone: "",
        mobile: "",
        date_of_birth: "",
        marketing_email: "",
        marketing_sms: "",
        marketing_mail: "",
        marketing_call: "",
        guest_booking_reference: "",
    }
});

export const useUserStore = defineStore(
    "user",
    () => {
        const state = reactive<UserState>(initialState());
        const systemStore = useSystemStore();
        const bookingStore = useBookingStore();
        const preArrivalStore = usePreArrivalStore();

        async function login(
            email: string,
            password: string
        ): Promise<boolean> {
            if (!email || !password) {
                return false;
            }

            state.isLoading = true;

            try {
                await api.get("sanctum/csrf-cookie");

                const response = await api.post("api/auth/login", {
                    email: email,
                    password: password,
                    session_id: systemStore.state.sessionId,
                    webfolio_id: systemStore.state.webfolioId,
                    guest_booking_reference: preArrivalStore.state.guestBookingRef,
                    booking_id: bookingStore.state.booking.id ?? null,
                });

                const { data: responseDataUser } = response.data;

                state.user = responseDataUser;
                state.isAuthenticated = true;

                if(response) {
                    emitEvent('analytics-event', {
                        type: 'login',
                        method: 'Website'
                    });
                }

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Login Failed",
                    message: state.error.message,
                });
                return false;
            } finally {
                if (state.isAuthenticated) {
                    const result = await systemStore.updateSession();

                    if (!result) {
                        await logout();

                        return false;
                    }

                    state.isLoading = false;

                    await systemStore.systemStatus();
                }
            }
        }

        async function register(userForm: UserForm): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/auth/register", {
                    booking_id: bookingStore.state.booking.id,
                    ...userForm
                });

                const { data: responseDataUser } = response.data;
                state.user = responseDataUser;
                state.isAuthenticated = true;

                if(response) {
                    emitEvent('analytics-event', {
                        type: 'sign_up',
                        method: 'Website',
                        value: bookingStore.state.booking.total,
                        currency: 'GBP'
                    });
                }

                return true;
            } catch (errorResponse: any) {
                state.formErrors = {
                    ...state.formErrors,
                    ...errorResponse.response?.data?.errors || {},
                };

                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                if (state.isAuthenticated) {
                    const result = await systemStore.updateSession();

                    if (!result) {
                        await logout();

                        return false;
                    }

                    state.isLoading = false;

                    await systemStore.systemStatus();
                }
            }
        }

        async function completeRegistration(
            userForm: CompleteRegistrationForm
        ): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post(
                    "api/auth/complete-registration",
                    { 
                        booking_id: bookingStore.state.booking.id,
                        ...userForm
                    }
                );

                const { data: responseDataUser } = response.data;

                state.user = responseDataUser;
                state.isAuthenticated = true;

                if(response) {
                    emitEvent('analytics-event', {
                        type: 'sign_up',
                        method: 'Website',
                        value: bookingStore.state.booking.total,
                        currency: 'GBP'
                    });
                }

                return true;
            } catch (errorResponse: any) {
                state.formErrors = {
                    ...state.formErrors,
                    ...errorResponse.response?.data?.errors || {},
                };

                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                if (state.isAuthenticated) {
                    const result = await systemStore.updateSession();

                    if (!result) {
                        await logout();

                        return false;
                    }

                    state.isLoading = false;

                    await systemStore.systemStatus();
                }
            }
        }

        async function createTemporaryUser(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/auth/temp/user", {
                    webfolio_id: systemStore.state.webfolioId,
                });

                state.user = {
                    first_name: "",
                    last_name: "",
                    email: "",
                    email_verified_at: null,
                    rs_customer_id: response.data.data.rs_customer_id,
                    rs_guid: response.data.data.rs_guid,
                    token: null,
                };

                state.isTempUser = true;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                state.isLoading = false;

                if (state.isTempUser) {
                    await systemStore.systemStatus();
                }
            }
        }

        async function useTemporaryDiningUser(): Promise<boolean> {
            state.isLoading = true;

            try {
                state.isTempUser = true;
                state.user = {
                    first_name: "",
                    last_name: "",
                    email: "",
                    email_verified_at: null,
                    rs_customer_id: Number(
                        import.meta.env.VITE_AFTERNOON_TEA_DEFAULT_CUSTOMER_ID
                    ),
                    rs_guid: import.meta.env
                        .VITE_AFTERNOON_TEA_DEFAULT_CUSTOMER_GUID,
                    token: null,
                };

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                state.isLoading = false;

                if (state.isTempUser) {
                    const result = await systemStore.updateSession();

                    if (!result) {
                        await logout();

                        return false;
                    }

                    await systemStore.systemStatus();
                }
            }
        }

        async function updateUser(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.get("api/auth/user");

                const { data: responseDataUser } = response.data;

                state.user = responseDataUser;

                state.isAuthenticated = true;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                if (state.isAuthenticated) {
                    const result = await systemStore.updateSession();

                    if (!result) {
                        await logout();

                        return false;
                    }

                    state.isLoading = false;

                    await systemStore.systemStatus();
                }
            }
        }

        async function logout(): Promise<boolean> {
            state.isLoading = true;

            try {
                await api.post("api/auth/logout");

                state.isAuthenticated = false;
                state.user = null;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function checkCustomerEmail(email: string): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/auth/verify/email", {
                    email: email,
                });

                if (response.data.data.account_exists) {
                    push.success({
                        title: "Account found",
                        message:
                            "You have an existing account, please enter your password to login",
                    });

                    return true;
                } else {
                    push.success({
                        title: "Account not found",
                        message:
                            "You do not have an existing account, please register to continue",
                    });

                    return false;
                }
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function setAuthenticationToken(token: string): Promise<boolean> {
            if (state.user) {
                state.user.token = token;
            } else {
                state.user = {
                    first_name: "",
                    last_name: "",
                    email: "",
                    email_verified_at: null,
                    rs_customer_id: null,
                    rs_guid: null,
                    token: token,
                };
            }

            return true;
        }

        async function requestPasswordReset(email: string): Promise<boolean> {
            state.isLoading = true;
            state.error.hasError = false;
            state.error.message = "";

            try {
                const response = await api.post("api/auth/password/email", {
                    email,
                });

                if (response) {
                    push.success({
                        title: "Password Reset",
                        message:
                            "Password reset link has been sent to your email.",
                    });
                    return true;
                } else {
                    state.error.hasError = true;
                    state.error.message = "Failed to send password reset link.";
                    return false;
                }
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred while requesting a password reset.";
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function updatePassword(
            email: string,
            password: string,
            token: string
        ): Promise<boolean> {
            state.isLoading = true;
            state.error.hasError = false;
            state.error.message = "";

            try {
                const response = await api.post("api/auth/password/reset", {
                    'email': email,
                    'password': password,
                    'token': token
                });

                if (response) {
                    push.success({
                        title: "Password Updated",
                        message:
                            "Password has been reset successfully.",
                    });
                    return true;
                } else {
                    state.error.hasError = true;
                    state.error.message = "Failed to update password";
                    return false;
                }
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred while updating your password.";
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        const $reset = () => {
            Object.assign(state, initialState());
        };

        return {
            state,
            login,
            register,
            createTemporaryUser,
            useTemporaryDiningUser,
            completeRegistration,
            logout,
            checkCustomerEmail,
            updateUser,
            setAuthenticationToken,
            requestPasswordReset,
            updatePassword,
            $reset,
        };
    },
    {
        persist: {
            storage: sessionStorage,
        },
    }
);
