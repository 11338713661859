<template>
    <div class="flex flex-col gap-2 flex-auto">
        <label
            for="date-input"
            class="font-subheading font-extrabold text-black-600 text-sm"
        >
            {{ $t("bookHome.dates") }}
        </label>

        <div
            class="relative border border-stone-900 bg-selectBG rounded-md"
            @click="toggleCalendar"
        >
            <input
                id="date-input"
                type="text"
                :placeholder="$t('datePicker.placeholder')"
                :value="formattedDateRange()"
                readonly
                class="font-body w-full px-4 py-1 leading-10 text-sm cursor-pointer rounded-md"
                :class="{ 'cursor-not-allowed': disabled }"
                aria-haspopup="dialog"
                :aria-expanded="showCalendar"
                @keydown="handleKeydown"
            />
            <div class="absolute top-1/2 right-2 -translate-y-1/2">
                <div v-if="hasSelectedNewDates">
                    <CompleteIcon size="size-4" />
                </div>
                <div v-else>
                    <DownArrowIcon />
                </div>
            </div>
        </div>

        <RateCalendar
            v-if="showCalendar && prop.rates === true"
            :start-date="bookingState.booking.start_date"
            :end-date="bookingState.booking.end_date"
            :promo="promoCode"
            @date-selected="handleDateSelected"
            @close="toggleCalendar"
            :mode="prop.mode"
        />
        <BaseCalendar
            v-else-if="showCalendar && prop.rates === false"
            :start-date="bookingState.booking.start_date"
            :end-date="bookingState.booking.start_date"
            :promo="promoCode"
            @date-selected="handleDateSelected"
            @close="toggleCalendar"
            :mode="prop.mode"
        />
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import RateCalendar from "@/components/global/RateCalendar.vue";
import BaseCalendar from "@/components/global/BaseCalendar.vue";
import moment from "moment";
import { storeToRefs } from "pinia";
import { useBookingStore } from "@/stores/booking/booking";
import DownArrowIcon from "@/components/icons/DownArrowIcon.vue";
import CompleteIcon from "@/components/icons/CompleteIcon.vue";

interface DateRange {
    start: moment.Moment | null;
    end: moment.Moment | null;
    mode: "range" | "day";
    rates: boolean;
}

const prop = defineProps<{
    mode: "range" | "day";
    rates: boolean;
    disabled: boolean;
}>();

const bookingStore = useBookingStore();
const { state: bookingState } = storeToRefs(bookingStore);

const showCalendar = ref(false);
const promoCode = ref(null);

const initialStartDate = ref<string | null>(null);
const initialEndDate = ref<string | null>(null);

onMounted(() => {
    initialStartDate.value = bookingStore.state.booking.start_date;
    initialEndDate.value = bookingStore.state.booking.end_date;
});

const toggleCalendar = () => {
    if (prop.disabled) return;
    showCalendar.value = !showCalendar.value;
};

const closeCalendarWithDelay = () => {
    setTimeout(() => {
        showCalendar.value = false;
    }, 500);
};

const handleDateSelected = (range: DateRange) => {
    if (range.start) {
        bookingState.value.booking.start_date =
            range.start.format("YYYY-MM-DD");

        if (prop.mode === "day") {
            bookingState.value.booking.end_date = range.start
                .clone()
                .format("YYYY-MM-DD");
        } else if (prop.mode === "range" && range.end) {
            bookingState.value.booking.end_date =
                range.end.format("YYYY-MM-DD");
        }

        closeCalendarWithDelay();
    }
};

const hasSelectedNewDates = computed(() => {
    const startChanged =
        initialStartDate.value !== bookingStore.state.booking.start_date;
    const endChanged =
        initialEndDate.value !== bookingStore.state.booking.end_date;
    return startChanged || endChanged;
});

const formattedDateRange = () => {
    const startDate = bookingStore.state.booking.start_date
        ? moment(bookingStore.state.booking.start_date)
        : null;
    const endDate = bookingStore.state.booking.end_date
        ? moment(bookingStore.state.booking.end_date)
        : null;

    if (!startDate || !startDate.isValid()) return "";

    const startFormatted = startDate.format("Do");
    const startMonth = startDate.format("MMMM");

    if (prop.mode === "day" || !endDate || !endDate.isValid()) {
        return `${startFormatted} ${startMonth}`;
    }

    const endFormatted = endDate.format("Do");
    const endMonth = endDate.format("MMMM");

    return startMonth === endMonth
        ? `${startFormatted} - ${endFormatted} ${startMonth}`
        : `${startFormatted} ${startMonth} - ${endFormatted} ${endMonth}`;
};

const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        toggleCalendar();
    }
};
</script>

<style lang="scss"></style>
