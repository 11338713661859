import auth from "./auth";
import bookingCompleted from "./bookingCompleted";
import bookingIncomplete from "./bookingIncomplete";
import guest from "./guest";
import inclusivesCompleted from "./inclusivesCompleted";
import experienceOnly from "./experienceOnly";
import preArrivalAuth from "./preArrivalAuth";
import bookingHasCartItems from "./bookingHasCartItems";
import bookingLocked from "./bookingLocked";
import hasDiningReservation from "./hasDiningReservation";

export default {
    auth,
    guest,
    bookingCompleted,
    bookingIncomplete,
    inclusivesCompleted,
    experienceOnly,
    preArrivalAuth,
    bookingHasCartItems,
    bookingLocked,
    hasDiningReservation
};
