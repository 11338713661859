<template>
    <PopupModel
        :title="$t('countdownModel.popupTitle')"
        :visible="warningModalVisible"
        @close="handleCloseWarning"
    >
        <p class="font-subheading">
            Your session is about to expire. Do you need more time?
        </p>
        <template #buttons>
            <DynamicButton @click="handleSessionUpdate" variant="solid" aria-label="I need more time"
                >I need more time</DynamicButton
            >
            <DynamicButton
                @click="handleCloseWarning"
                variant="outline"
                aria-label="Close"
                >{{ $t("common.close") }}</DynamicButton
            >
        </template>
    </PopupModel>

    <PopupModel
        title="Session Expired"
        :visible="sessionExpiredModalVisible"
        @close="handleSessionExpired"
    >
        <p class="font-subheading">
            Your session has expired. Please start again.
        </p>
        <template #buttons>
            <DynamicButton
                @click="handleSessionExpired"
                variant="outline"
                aria-label="Start again"
            >
                Start Again
            </DynamicButton>
        </template>
    </PopupModel>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from "vue";
import { useSystemStore } from "@/stores/system/system";
import { useResetStore } from "@/helpers/useResetStore";
import DynamicButton from "@/components/forms/DynamicButton.vue";
import PopupModel from "@/components/global/PopupModel.vue";
import router from "@/router";

const props = defineProps();

const systemStore = useSystemStore();
const resetStore = useResetStore();

const warningModalVisible = ref(false);
const sessionExpiredModalVisible = ref(false);

const remainingTime = ref<number>(
    systemStore.state.remainingTimeoutMinutes * 60
);
const formattedMinutes = ref<string>("00");
const formattedSeconds = ref<string>("00");

const warningDismissed = ref(false);

let pollingInterval: ReturnType<typeof setInterval> | null = null;
let countdownInterval: ReturnType<typeof setInterval> | null = null;

const showWarningModal = () => {
    warningModalVisible.value = true;
};

const showSessionExpiredModal = () => {
    sessionExpiredModalVisible.value = true;
    warningModalVisible.value = false;
};

const formatTime = (time: number): string => time.toString().padStart(2, "0");

const updateFormattedTime = () => {
    formattedMinutes.value = formatTime(Math.floor(remainingTime.value / 60));
    formattedSeconds.value = formatTime(remainingTime.value % 60);
};

const checkRemainingTime = async () => {
    try {
        await systemStore.systemStatus();

        remainingTime.value = systemStore.state.remainingTimeoutMinutes * 60;
        
        updateFormattedTime();

        if (
            remainingTime.value > 0 &&
            remainingTime.value <= 300 &&
            !warningDismissed.value
        ) {
            showWarningModal();
            startCountdown();
        } else if (remainingTime.value > 300 && countdownInterval) {
            warningModalVisible.value = false;
            clearInterval(countdownInterval);
            countdownInterval = null;
        }

        if (remainingTime.value <= 0) {
            showSessionExpiredModal();
        }
    } catch (error) {
        console.error("Error fetching system status", error);
    }
};

const startCountdown = () => {
    if (countdownInterval) return;
    countdownInterval = setInterval(() => {
        if (remainingTime.value > 0) {
            remainingTime.value--;
            updateFormattedTime();
        } else {
            showSessionExpiredModal();
            clearInterval(countdownInterval!);
        }
    }, 1000);
};

const startPolling = () => {
    checkRemainingTime();
    pollingInterval = setInterval(checkRemainingTime, 30000);
};

const handleSessionExpired = async () => {
    resetStore.all();
    await systemStore.systemStatus();
    router.push({ name: "Index" });
    systemStore.createSession();
    sessionExpiredModalVisible.value = false;
    warningModalVisible.value = false;
};

const handleSessionUpdate = async () => {
    await systemStore.systemStatus();
    systemStore.updateSession();
    sessionExpiredModalVisible.value = false;
    warningModalVisible.value = false;
};

const handleCloseWarning = () => {
    warningModalVisible.value = false;
    warningDismissed.value = true;
};

onMounted(() => {
    setTimeout(() => {
        startPolling();
    }, 5 * 60 * 1000);
});

onUnmounted(() => {
    if (pollingInterval) {
        clearInterval(pollingInterval);
    }
    if (countdownInterval) {
        clearInterval(countdownInterval);
    }
});
</script>

<style scoped lang="scss">
:deep(button) {
    @apply flex-1;
}
</style>
