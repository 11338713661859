<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        :stroke="color"
        :class="`${size}`"
        aria-labelledby="closeIconTitle closeIconDesc"
        role="img"
    >
        <title id="closeIconTitle">Close Icon</title>
        <desc id="closeIconDesc">Close Icon</desc>
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
        />
    </svg>
</template>

<script setup lang="ts">
interface Props {
    color?: string;
    size?: string;
}

const props = defineProps<Props>();

const color = props.color || "currentColor";
const size = props.size || "size-6";
</script>

<style scoped lang="scss"></style>
