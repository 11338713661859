<template>
    <div class="flex flex-col gap-1 w-full">
        <label :for="id" :class="labelStyle" v-if="!hideLabel">
            {{ label }}
            <span
                v-if="required"
                class="font-subheading font-semibold capitalize text-[#6E827C] text-xs"
                >required</span
            >
        </label>
        <input
            :id="id"
            :name="name"
            :type="type"
            :placeholder="placeholder"
            :class="inputStyle"
            :required="required"
            :readonly="readonly"
            :autocomplete="autocomplete"
            :autocapitalize="autocapitalize"
            :aria-required="required"
            :aria-readonly="readonly"
            :aria-invalid="ariaInvalid"
            :value="modelValue"
            @input="handleInput"
            @blur="handleBlur"
        />
        <ul v-if="errors && errors.length" class="text-danger-900 text-sm">
            <li v-for="(error, index) in errors" :key="index">
                {{ error }}
            </li>
        </ul>
        <p v-if="showInvalidFormatMessage" class="text-danger-900 text-sm">
            {{ invalidFormatMessage }}
        </p>
        <p v-if="helpText" class="text-stone-200 text-sm">
            {{ helpText }}
        </p>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

interface Props {
    modelValue?: string;
    name: string;
    label?: string;
    placeholder?: string;
    labelStyle?: string;
    hideLabel?: boolean;
    inputStyle?: string;
    type?: string;
    id?: string;
    required?: boolean;
    readonly?: boolean;
    autocomplete?: string;
    autocapitalize?: string;
    errors?: string | string[];
    helpText?: string;
    pattern?: RegExp;
    invalidFormatMessage?: string;
    inputHandler?: (value: string) => void;
}

const props = defineProps<Props>();
const emit = defineEmits<{
    (event: "update:modelValue", value: string): void;
    (event: "blur", value: string): void;
}>();

const id = props.id || props.name;
const type = props.type || "text";
const name = props.name;
const label = props.label;
const placeholder = props.placeholder || "";
const autocomplete = props.autocomplete || "on";
const autocapitalize = props.autocapitalize || "off";
const labelStyle = `font-subheading font-extrabold text-black-600 text-sm flex justify-between items-center ${
    props.labelStyle || ""
}`;
const inputStyle = `relative w-full border border-stone-900 bg-selectBG rounded-md px-4 py-1 leading-10 text-sm ${
    props.inputStyle || ""
}`;

const modelValue = ref(props.modelValue || "");
const ariaInvalid = ref(false);
const hideLabel = props.hideLabel || false;
const showInvalidFormatMessage = ref(false);

const handleInput = (event: Event) => {
    const input = event.target as HTMLInputElement;
    modelValue.value = input.value;

    if (props.pattern) {
        showInvalidFormatMessage.value = !props.pattern.test(modelValue.value);
        ariaInvalid.value = showInvalidFormatMessage.value;
    } else {
        showInvalidFormatMessage.value = false;
        ariaInvalid.value = false;
    }

    emit("update:modelValue", modelValue.value);

    if (props.inputHandler) {
        props.inputHandler(modelValue.value);
    }
};

const handleBlur = () => {
    emit("blur", modelValue.value ?? "");
};

watch(
    () => props.modelValue,
    (newValue) => {
        modelValue.value = newValue ?? "";
    }
);
</script>

<style scoped lang="scss">
:deep(input) {
    &[readonly] {
        @apply bg-gray-100 cursor-not-allowed;
    }
}
</style>
