<template>
    <transition name="fade" appear>
        <div
            v-show="visible"
            class="overlay | md:hidden"
            aria-hidden="true"
            @click="closeCalendar"
            @keydown.esc="closeCalendar"
        ></div>
    </transition>

    <transition name="fade-slide" appear>
        <div
            v-show="visible"
            class="rate-calendar fixed left-0 right-0 bottom-0 w-full h-min px-6 pb-6 rounded bg-ivory-800 z-[61] | md:absolute md:h-full md:flex md:justify-start md:items-center md:pb-2"
            ref="calendarRef"
        >
            <div
                class="touch-control w-24 h-1 bg-black-900/10 rounded-md mx-auto mt-6 mb-10 md:hidden"
                @click="closeCalendar"
                ref="touchControlRef"
            ></div>
            <div class="hidden md:flex md:absolute md:top-3 md:right-4">
                <DynamicButton
                    variant="text"
                    size="none"
                    color="transparent"
                    additionalClasses="!gap-0"
                    @click="closeCalendar"
                    aria-label="Close calendar"
                >
                    <template #icon-before>
                        <CloseIcon />
                    </template>
                    {{ $t("common.close") }}
                </DynamicButton>
            </div>
            <div class="calendar-container max-w-sm w-full mx-auto">
                <VueDatePicker
                    v-model="formattedDateRange"
                    locale="en"
                    inline
                    auto-apply
                    month-name-format="long"
                    hide-offset-dates
                    :range="isRangeMode ? { noDisabledRange: true } : false"
                    :month-change-on-scroll="false"
                    :min-date="new Date()"
                    prevent-min-max-navigation
                    :day-names="[
                        $t('rateCalendar.monday'),
                        $t('rateCalendar.tuesday'),
                        $t('rateCalendar.wednesday'),
                        $t('rateCalendar.thursday'),
                        $t('rateCalendar.friday'),
                        $t('rateCalendar.saturday'),
                        $t('rateCalendar.sunday'),
                    ]"
                    :day-class="dayClass"
                    :disabled-dates="disabledDates"
                    :enable-time-picker="false"
                    @update:model-value="updateDateRange"
                    @invalid-select="handleInvalidSelect"
                    @update-month-year="handleMonthYear"
                >
                    <template #day="{ day, date }">
                        <div class="flex flex-col text-sm font-bold">
                            {{ day }}
                            <span
                                v-if="
                                    hasPrice(date) &&
                                    bookingStore.state.booking.experience !==
                                        'dining'
                                "
                                class="text-xxs text-stone-900"
                            >
                                £{{
                                    Math.floor(
                                        parseFloat(
                                            getDate(
                                                moment(date).format(
                                                    "YYYY-MM-DD"
                                                )
                                            )?.lowest_rate?.single_rate || "0"
                                        )
                                    )
                                }}
                            </span>
                            <span v-else class="text-xs text-textcolor">
                                <span>&nbsp;</span>
                            </span>
                        </div>
                    </template>
                </VueDatePicker>
                <div
                    class="mt-6 flex gap-8 max-w-sm w-full items-center justify-between"
                >
                    <div
                        v-if="isRangeMode"
                        class="text-xl font-subheading font-semibold flex flex-col"
                        aira-live="polite"
                    >
                        <PriceDisplay
                            v-if="isRangeSelected"
                            :price="totalPrice"
                            :priceText="$t('common.from')"
                            :text="$t('common.perperson')"
                        />
                    </div>
                    <DynamicButton
                        variant="solid"
                        :disabled="!isRangeSelected"
                        additionalClasses="select-dates"
                        @click="confirmDate"
                        aria-label="Confirm selected dates"
                    >
                        <span v-if="isRangeMode">
                            {{ $t("rateCalendar.confirmDatesText") }}
                        </span>
                        <span v-else>
                            {{ $t("rateCalendar.confirmDateText") }}
                        </span>
                    </DynamicButton>
                </div>
            </div>

            <LoadingLogo
                v-if="calendarStore.state.isLoading"
                :loading="calendarStore.state.isLoading"
                :message="$t('rateCalendar.loadingText')"
            />
        </div>
    </transition>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useCalendarStore } from "@/stores/calendar/calendar";
import { useBookingStore } from "@/stores/booking/booking";
import { useSwipeDownClose } from "@/composables/useSwipeDownClose";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import PriceDisplay from "@/components/global/PriceDisplay.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import DynamicButton from "@/components/forms/DynamicButton.vue";
import LoadingLogo from "@/components/global/LoadingLogo.vue";
import moment from "moment";

const calendarStore = useCalendarStore();
const bookingStore = useBookingStore();

interface Props {
    startDate: string | null;
    endDate: string | null;
    promo: string | null;
    mode: "range" | "day";
}

interface DateRange {
    start: moment.Moment | null;
    end: moment.Moment | null;
}

const props = defineProps<Props>();
const emit = defineEmits(["update:modelValue", "close", "date-selected"]);

const visible = ref(true);
const totalPrice = ref(0);
const dateRange = ref<DateRange>({ start: null, end: null });
const calendarRef = ref<HTMLElement | null>(null);
const touchControlRef = ref(null);

const formattedDateRange = computed<[Date] | [Date, Date]>({
    get() {
        if (props.mode === "day") {
            return dateRange.value.start
                ? dateRange.value.start.toDate()
                : null;
        } else if (props.mode === "range") {
            return dateRange.value.start && dateRange.value.end
                ? [dateRange.value.start.toDate(), dateRange.value.end.toDate()]
                : [];
        }
        return [];
    },
    set(value: [Date] | [Date, Date]) {
        if (props.mode === "day") {
            dateRange.value.start = value[0] ? moment(value[0]) : null;
            dateRange.value.end = dateRange.value.start
                ? moment(dateRange.value.start).add(1, "day")
                : null;
        } else if (props.mode === "range") {
            dateRange.value.start = value[0] ? moment(value[0]) : null;
            dateRange.value.end = value[1] ? moment(value[1]) : null;
        }
    },
});

const isRangeMode = computed(() => props.mode === "range");

const updateDateRange = (value: string | [string, string]) => {
    if (Array.isArray(value)) {
        const [start, end] = value;

        dateRange.value.start = start
            ? moment(start).isValid()
                ? moment(start)
                : null
            : null;
        dateRange.value.end = end
            ? moment(end).isValid()
                ? moment(end)
                : null
            : null;

        if (props.mode === "day" && dateRange.value.start) {
            dateRange.value.end = moment(dateRange.value.start).add(1, "day");
        }
    } else {
        dateRange.value.start = value
            ? moment(value).isValid()
                ? moment(value)
                : null
            : null;

        if (props.mode === "day" && dateRange.value.start) {
            dateRange.value.end = moment(dateRange.value.start).add(1, "day");
        } else {
            dateRange.value.end = null;
        }
    }

    if (dateRange.value.end) {
        calculateTotalPrice();
    }
};

const handleInvalidSelect = () => {
    push.error({
        title: "Sorry!",
        message: "Your selection is invalid. Please try again.",
    });
};

const calculateTotalPrice = () => {
    let sum = 0;
    calendarStore.state.dates.forEach(
        (item: {
            date: moment.MomentInput;
            lowest_rate: { single_rate: string };
        }) => {
            const startDate = moment(item.date);

            if (
                startDate.isBetween(
                    dateRange.value.start,
                    dateRange.value.end,
                    "day",
                    "[)"
                ) &&
                item.lowest_rate
            ) {
                sum += parseFloat(item.lowest_rate.single_rate);
            }
        }
    );

    totalPrice.value = sum;
};

const getDate = (date: string) => {
    return calendarStore.state.dates.find(
        (item: { date: string }) => item.date === date
    );
};

const hasPrice = (date: Date) => {
    const dateInfo = getDate(moment(date).format("YYYY-MM-DD"));
    return dateInfo && dateInfo.lowest_rate && dateInfo.lowest_rate.single_rate;
};

const disabledDates = computed(() => {
    return calendarStore.state.dates
        .filter(
            (item: { date: string; lowest_rate: { single_rate: string } }) =>
                !item.lowest_rate || !item.lowest_rate.single_rate
        )
        .map((item: { date: string }) => new Date(item.date));
});

const dayClass = (date: Date) => {
    return !hasPrice(date) && isRangeMode.value ? "dp__cell_disabled" : "";
};

const isRangeSelected = computed(() => {
    return dateRange.value.start !== null && dateRange.value.end !== null;
});

const confirmDate = () => {
    if (isRangeSelected.value) {
        closeCalendar();
        emit("date-selected", dateRange.value);
    }
};

const closeCalendar = () => {
    visible.value = false;

    setTimeout(() => {
        emit("close");
    }, 500);
};

const handleMonthYear = (newMonth: Date) => {
    const currentMonth = moment().startOf("month");
    const selectedMonth = moment(newMonth).startOf("month");

    const startDate = moment(newMonth).startOf("month").format("YYYY-MM-DD");
    const endDate = moment(newMonth).endOf("month").format("YYYY-MM-DD");
    calendarStore.fetchCalendar(startDate, endDate);
};

const handleEscape = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
        closeCalendar();
    }
};

onMounted(async () => {
    window.addEventListener("keydown", handleEscape);
    await calendarStore.fetchCalendar();

    if (props.startDate) {
        dateRange.value.start = moment(props.startDate);
    }

    if (props.endDate) {
        dateRange.value.end = moment(props.endDate);
    }

    if (props.mode === "day" && dateRange.value.start) {
        dateRange.value.end = moment(dateRange.value.start).add(1, "day");
    }

    if (dateRange.value.start && dateRange.value.end) {
        await calculateTotalPrice();
    }
});

onUnmounted(() => {
    window.removeEventListener("keydown", handleEscape);
});

watch(
    () => [props.startDate, props.endDate],
    ([start, end]) => {
        dateRange.value.start = start ? moment(start) : null;
        dateRange.value.end = end
            ? moment(end)
            : isRangeMode.value
            ? moment(start).add(1, "day")
            : null;
        if (dateRange.value.start && dateRange.value.end) calculateTotalPrice();
    }
);

useSwipeDownClose(calendarRef, closeCalendar);
</script>

<style scoped lang="scss">
:deep(.select-dates) {
    @apply flex-1 bg-gold-900 hover:bg-gold-800;
}
:deep(.dp__main) {
    * {
        @apply shadow-none outline-none border-none bg-transparent rounded-none;
    }

    > div {
        @apply w-full;

        &:first-of-type {
            @apply hidden;
        }
    }

    .dp__menu_inner {
        @apply p-0;
    }

    .dp--header-wrap {
        @apply mb-2;
    }

    .dp__month_year_select {
        @apply text-black-900 font-heading text-base;
    }

    .dp__calendar_header_item {
        @apply text-secondary font-subheading font-bold uppercase text-center text-sm py-0 p-0 h-auto;
    }

    .dp__month_year_wrap {
        @apply flex items-center justify-center gap-2;
    }

    .dp__month_year_select {
        @apply w-auto;

        // &:last-of-type {
        //     @apply pointer-events-none;
        // }
    }

    .dp__calendar_item {
        @apply flex items-start font-subheading font-bold uppercase h-14;
    }

    .dp__calendar_row {
        @apply mt-0 mb-0;
    }

    .dp__cell_inner {
        @apply flex-1 flex items-center h-full text-center p-0;

        &.dp__cell_disabled {
            span {
                @apply opacity-0;
            }
        }
    }

    .dp__range_start {
        @apply bg-primary text-ivory-200;

        span {
            @apply transition-all text-ivory-200;
        }
    }

    .dp__range_end {
        @apply border border-solid border-primary text-primary;

        span {
            @apply transition-all opacity-0;
        }
    }

    .dp__date_hover_start,
    .dp__date_hover {
        @apply bg-transparent;
    }

    .dp__range_between {
        @apply bg-highlight text-ivory-300;

        span {
            @apply text-ivory-200;
        }
    }

    .dp__date_hover_end {
        @apply border border-solid border-primary text-primary bg-transparent;
    }

    .dp__active_date {
        @apply bg-primary text-ivory-300;

        span {
            @apply text-ivory-300;
        }
    }

    .dp--tp-wrap {
        @apply w-full max-w-full my-4;
    }

    .dp__tp_inline_btn_top,
    .dp__tp_inline_btn_bottom {
        @apply hidden;
    }

    .dp__time_display {
        @apply text-primary font-subheading font-semibold text-lg p-0;
    }

    .dp__time_col_reg_inline {
        @apply p-1;
    }

    .dp__overlay_cell {
        @apply text-primary font-subheading font-normal p-1;

        &_active {
            @apply bg-primary text-ivory-300 font-bold;
        }
    }

    .dp__overlay_cell_disabled {
        @apply opacity-40;
    }

    .dp__time_picker_overlay_container,
    .dp__overlay_container,
    .dp__overlay {
        @apply bg-ivory-900 opacity-95 justify-center;

        .dp__button_bottom {
            @apply hidden;
        }
    }

    .dp__overlay_container {
        @apply flex;
    }

    .dp__overlay_col {
        @apply p-0;
    }
}
</style>
