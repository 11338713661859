import { reactive } from "vue";
import { defineStore } from "pinia";
import type { Error, GiftCardForm } from "@/types/index";
import { useBookingStore } from "@/stores/booking/booking";
import { useSystemStore } from "@/stores/system/system";
import api from "@/api/axios";
import { emitEvent } from "@/helpers/eventEmitter";
interface RetailState {
    isLoading: boolean;
    categories: any[];
    selectedCategory: number | false;
    gift_cards: any[];
    form: GiftCardForm;
    error: Error;
}

const initialState = (): RetailState => ({
    isLoading: false,
    categories: [],
    selectedCategory: 1,
    gift_cards: [],
    form: {
        customer_id: 24,
        email_address: "",
        issue_to_name: "",
        item_id: null,
        layout: 0,
        mail_to_address: {
            address1: "",
            address2: "",
            city: "",
            state_prov: "",
            country: "",
            post_code: "",
            type: "",
        },
        message: "",
        method: "M",
        wrapping_id: 0,
        shipping_id: null,
        package_certificate: {
            package_item: {
                email_address: "",
                issue_to_name: "",
                layout: 0,
                mail_to_address: {
                    address1: "",
                    address2: "",
                    city: "",
                    state_prov: "",
                    country: "",
                    post_code: "",
                    type: "",
                },
                message: "",
                id: 0,
            },
        },
    },
    error: {
        hasError: false,
        message: "",
    },
});

export const useRetailStore = defineStore(
    "retail",
    () => {
        const state = reactive<RetailState>(initialState());

        const bookingStore = useBookingStore();
        const systemStore = useSystemStore();

        async function fetchCategories(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/gift-cards/categories", {
                    venue_id: bookingStore.state.booking.venue_id ?? null,
                });

                state.categories = response.data.data;
                state.selectedCategory = state.categories[0]?.id ?? 1;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function fetchGiftCards(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/gift-cards/list", {
                    category_id: state.selectedCategory
                        ? state.selectedCategory
                        : null,
                    venue_id: bookingStore.state.booking.venue_id ?? null,
                });

                state.gift_cards = response.data.data;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function purchaseGiftCard(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/gift-cards/purchase", {
                    webfolio_id: systemStore.state.webfolioId,
                    session_id: systemStore.state.sessionId,
                    booking_id: bookingStore.state.booking.id,
                    ...state.form,
                });

                bookingStore.state.booking = response.data.data;

                push.success({
                    title: "Order Update",
                    message: "Your gift card has been added to the cart.",
                });

                if (response.data.analytics) {
                    emitEvent("analytics-event", {
                        type: "add_to_cart",
                        venue:
                            bookingStore.state.booking.venue_id === 100
                                ? "hoar-cross-hall"
                                : "eden-hall",
                        ecommerce: {
                            currency: "GBP",
                            add: {
                                products: [response.data.analytics.product],
                            },
                        },
                    });
                }

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function removeGiftCard(
            retailFolioId: number,
            retailFolioItemId: number
        ): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/gift-cards/remove", {
                    webfolio_id: systemStore.state.webfolioId,
                    session_id: systemStore.state.sessionId,
                    booking_id: bookingStore.state.booking.id,
                    retail_folio_id: retailFolioId,
                    retail_folio_item_id: retailFolioItemId,
                });

                bookingStore.state.booking = response.data.data;

                push.success({
                    title: "Order Update",
                    message: "Your gift card has been removed from the cart.",
                });

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        const resetForm = () => {
            state.form = initialState().form;
        };

        const $reset = () => {
            Object.assign(state, initialState());
        };

        return {
            state,
            fetchCategories,
            fetchGiftCards,
            purchaseGiftCard,
            removeGiftCard,
            resetForm,
            $reset,
        };
    },
    {
        persist: {
            storage: sessionStorage,
        },
    }
);
