<template>
    <div class="md:mb-20 md:px-4">
        <BookingCard
            :heading="$t('bookHome.heading')"
            variant="bookingpage"
            id="main"
            role="main"
            :src="bookingImage.src"
            imageClasses="w-screen aspect-[5/2] | md:w-[330px] md:aspect-auto md:h-auto"
        >
            <div class="flex flex-col pb-20 | md:w-full md:pb-12">
                <div
                    class="flex-1 flex justify-start flex-col px-6 gap-6 | md:flex-auto md:w-full md:px-10"
                >
                    <CustomSelect
                        v-model="bookingState.booking.venue_id"
                        :label="$t('bookHome.spa')"
                        :placeholder="$t('bookHome.locationPlaceholder')"
                        :options="locationOptions"
                        aria-label="Select Location"
                    />
                    <transition name="slide-fade" appear>
                        <div v-show="showExperienceSelect">
                            <CustomSelect
                                v-model="bookingState.booking.experience"
                                :label="$t('bookHome.experience')"
                                :placeholder="
                                    $t('bookHome.experiencePlaceholder')
                                "
                                :options="experienceOptions"
                                aria-label="Select Experience"
                            />
                        </div>
                    </transition>
                    <transition name="slide-fade" appear>
                        <div
                            v-show="bookingState.booking.experience"
                            class="flex flex-col gap-6 | lg:w-full lg:flex-row lg:flex-wrap"
                        >
                            <div
                                class="md:flex-1"
                                v-if="
                                    bookingState.booking.experience !==
                                    'gift_cards'
                                "
                            >
                                <DatePicker
                                    v-if="
                                        bookingState.booking.experience ===
                                        'stay'
                                    "
                                    mode="range"
                                    :rates="true"
                                    aria-label="Select Date Range"
                                    role="group"
                                    :disabled="!bookingState.booking.venue_id"
                                />
                                <DatePicker
                                    v-else-if="
                                        bookingState.booking.experience ===
                                            'spa_day' ||
                                        bookingState.booking.experience ===
                                            'spa_evening'
                                    "
                                    mode="day"
                                    :rates="true"
                                    :disabled="!bookingState.booking.venue_id"
                                />
                                <DatePicker
                                    v-else-if="
                                        bookingState.booking.experience ===
                                            'treatments' ||
                                        bookingState.booking.experience ===
                                            'dining'
                                    "
                                    mode="day"
                                    :rates="false"
                                    aria-label="Select Date"
                                    role="group"
                                    :disabled="!bookingState.booking.venue_id"
                                />
                            </div>

                            <div
                                class="md:flex-1 empty:hidden"
                                v-if="
                                    bookingState.booking.experience !==
                                    'gift_cards'
                                "
                            >
                                <RoomSelection
                                    v-if="
                                        bookingState.booking.experience ===
                                        'stay'
                                    "
                                    :maxAdults="4"
                                    :modelValue="bookingState.booking.pms_items"
                                    @update:modelValue="handleUpdateRooms"
                                />
                                <GuestSelection
                                    :maxAdults="6"
                                    v-else-if="
                                        bookingState.booking.experience ===
                                            'spa_day' ||
                                        bookingState.booking.experience ===
                                            'spa_evening'
                                    "
                                    @update:modelValue="handleUpdateRooms"
                                />
                                <GuestSelection
                                    :maxAdults="6"
                                    v-else-if="
                                        bookingState.booking.experience ===
                                        'dining'
                                    "
                                    mode="single"
                                    @update:modelValue="handleGuestsUpdate"
                                />
                            </div>

                            <div class="md:flex-auto md:w-full">
                                <DynamicButton
                                    v-show="bookingState.booking.experience"
                                    variant="solid"
                                    @click="startBooking"
                                    :disabled="
                                        isLocked ||
                                        !bookingState.booking.experience
                                    "
                                    additionalClasses="search-button mt-auto w-full"
                                    aria-label="Search"
                                >
                                    {{ $t("common.search") }}
                                </DynamicButton>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </BookingCard>
    </div>

    <PopupModel
        :visible="isLocked && !isLoading"
        :title="$t('bookHome.existingBookingTitle')"
        role="dialog"
        aria-labelledby="existing-booking-title"
        aria-describedby="existing-booking-message"
    >
        <p id="existing-booking-message">
            {{ $t("bookHome.existingBookingMessage") }}
        </p>

        <template #buttons>
            <DynamicButton
                @click="bookingStepsStore.goToNextUncompletedStep()"
                variant="solid"
                aria-label="Continue Booking"
            >
                {{ $t("common.continue") }}
            </DynamicButton>
            <DynamicButton
                @click="resetBookingWithLoader()"
                variant="outline"
                aria-label="Start Over"
            >
                {{ $t("common.startOver") }}
            </DynamicButton>
        </template>
    </PopupModel>

    <LoadingLogo v-if="isLoading" :loading="isLoading" />
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import type { ComputedRef } from "vue";
import DynamicButton from "@/components/forms/DynamicButton.vue";
import BookingCard from "@/components/global/BookingCard.vue";
import RoomSelection from "@/components/forms/RoomSelection.vue";
import GuestSelection from "@/components/forms/GuestSelection.vue";
import CustomSelect from "@/components/forms/CustomSelect.vue";
import DatePicker from "@/components/forms/DatePicker.vue";
import PopupModel from "@/components/global/PopupModel.vue";
import type { Option, RoomConfig } from "@/types";
import { useBookingStore } from "@/stores/booking/booking";
import { storeToRefs } from "pinia";
import { useBookingStepsStore } from "@/stores/booking/bookingSteps";
import { useRatesStore } from "@/stores/rates/rates";
import LoadingLogo from "@/components/global/LoadingLogo.vue";
import { emitEvent } from "@/helpers/eventEmitter";

const bookingStore = useBookingStore();
const bookingStepsStore = useBookingStepsStore();
const ratesStore = useRatesStore();
const isLoading = ref(false);

const { state: bookingState } = storeToRefs(bookingStore);

const handleUpdateRooms = (updatedRooms: RoomConfig[]) => {
    const totalGuests = updatedRooms.reduce(
        (sum, guest) => sum + guest.adults,
        0
    );
    bookingState.value.booking.guests = totalGuests;
    bookingState.value.booking.pms_items = updatedRooms;
};

const handleGuestsUpdate = (guests: number) => {
    bookingState.value.booking.guests = guests;
};

const isLocked = computed(() => {
    return bookingStore.state.booking.is_experience_locked ?? false;
});

const locationOptions: Option[] = [
    { label: "Hoar Cross Hall Spa Hotel", value: 100 },
    { label: "Eden Hall Spa", value: 300 },
];

const experienceOptions: ComputedRef<Option[]> = computed(() => {
    if (bookingStore.state.booking.venue_id === 100) {
        return [
            { label: "Stay", value: "stay" },
            { label: "Spa Day", value: "spa_day" },
            { label: "Spa Evening", value: "spa_evening" },
            { label: "Treatments", value: "treatments" },
            { label: "Gift Cards", value: "gift_cards" },
            { label: "Afternoon Tea", value: "dining" },
        ] as Option[];
    } else {
        return [
            { label: "Spa Day", value: "spa_day" },
            { label: "Spa Evening", value: "spa_evening" },
            { label: "Treatments", value: "treatments" },
            { label: "Gift Cards", value: "gift_cards" },
        ] as Option[];
    }
});

type LocationImageMap = {
    [key: string]: {
        src: string;
        alt: string;
    };
};

const locationImages: LocationImageMap = {
    "100": {
        src: "https://barons-eden-cdn.fra1.digitaloceanspaces.com/page/sections/cta/XlSxe30Osv2zewYARr1BSRMDvKvCC0qLahfuxI0T.jpeg",
        alt: "Hoar Cross Hall Spa Hotel",
    },
    "300": {
        src: "https://barons-eden-cdn.fra1.digitaloceanspaces.com/page/sections/cta/P9ssT2DIfH0ifbZBAQFwajtvk6w7ub0BYG39vGVR.jpeg",
        alt: "Eden Hall Spa",
    },
};

const bookingImage = computed(() => {
    return (
        locationImages[bookingStore.state.booking.venue_id as number] || {
            src: "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/rrPgdVkguLvVartn75QfX30nrIwQoHPcPem82o9Z.avif",
            alt: "Luxury Hotel & Spa Stays",
        }
    );
});

const resetBookingWithLoader = async () => {
    try {
        isLoading.value = true;

        await bookingStore.resetBooking();
    } catch (error) {
        console.error("Error resetting booking", error);
    } finally {
        isLoading.value = false;
    }
};

const startBooking = async () => {
    try {
        let booking;

        if (bookingStore.state.booking.id !== null) {
            booking = await bookingStore.updateBooking();
        } else {
            booking = await bookingStore.createBooking();
        }

        if (booking) {
            ratesStore.$reset();
            bookingStepsStore.completeCurrentStep();
            bookingStepsStore.goToNextStep();

            emitEvent("analytics-event", {
                type: "journey_begin",
                venue:
                    bookingStore.state.booking.venue_id === 100
                        ? "hoar-cross-hall"
                        : "eden-hall",
            });
        }
    } catch (error: any) {
        console.error("Error starting booking", error);

        throw error;
    }
};

const showExperienceSelect = computed(() => {
    return !!bookingStore.state.booking.venue_id;
});

watch(
    () => bookingStore.state.booking.venue_id,
    async (newVal) => {
        if (newVal) {
            bookingStore.state.booking.experience = null;
            bookingStore.resetPmsItems();
        }
    }
);

watch(
    () => bookingStore.state.booking.experience,
    async (newVal) => {
        if (newVal === "spa_day" || newVal === "spa_evening") {
            bookingStore.state.booking.end_date =
                bookingStore.state.booking.start_date;
            bookingStore.state.booking.pms_items.forEach((pmsItem) => {
                pmsItem.adults = 1;
            });
        } else if (newVal === "stay") {
            bookingStore.state.booking.pms_items.forEach((pmsItem) => {
                bookingStore.state.booking.start_date =
                    bookingStore.initialState().booking.start_date;
                bookingStore.state.booking.end_date =
                    bookingStore.initialState().booking.end_date;
            });
        }
    }
);

onMounted(() => {
    if (bookingStore.state.booking.status === "complete") {
        bookingStore.$reset();
        ratesStore.$reset();
        bookingStepsStore.$reset();
    }

    bookingStepsStore.initializeCurrentStep();
});
</script>

<style scoped lang="scss">
:deep() {
    .search-button {
        @apply bg-primary hover:bg-primary-hover;
    }
}
</style>
