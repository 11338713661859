<template>
    <transition name="fade" appear>
        <div v-show="loading" aria-live="assertive" role="status">
            <div
                class="fixed bg-ivory-900/95 inset-0 flex flex-col items-center justify-center gap-4 z-[100]"
            >
                <div class="w-48 h-auto">
                    <component :is="currentLogoComponent" />
                </div>
                <LoadingSpinner :message="message" />
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { useCurrentTheme } from "../../composables/useCurrentTheme";
import DefaultLogo from "@/components/logos/DefaultLogo.vue";
import HoarcrosshallLogo from "@/components/logos/HoarcrosshallLogo.vue";
import EdenHallLogo from "@/components/logos/EdenHallLogo.vue";

const props = defineProps<{
    loading: boolean;
    message?: string;
}>();

const message = ref(props.message || "just a moment");
const currentTheme = useCurrentTheme();

const logoComponents: Record<string, typeof DefaultLogo> = {
    default: DefaultLogo,
    hoarcrosshall: HoarcrosshallLogo,
    edenhall: EdenHallLogo,
};

const currentLogoComponent = computed(() => logoComponents[currentTheme.value]);
</script>

<style scoped lang="scss"></style>
