import { reactive } from "vue";
import { defineStore } from "pinia";
import type { Booking, Error, Itinary } from "@/types/index";
import api from "@/api/axios";
import moment from "moment";
import { useSystemStore } from "@/stores/system/system";
import { useResetStore } from "@/helpers/useResetStore";

interface BookingStoreState {
    isLoading: boolean;
    booking: Booking;
    webfolio: any[];
    itinary: Itinary[];
    orderReference: number | null | undefined;
    error: Error;
}

const initialState = (): BookingStoreState => ({
    isLoading: false,
    booking: {
        id: null,
        venue_id: null,
        experience: null,
        start_date: moment().add(1, "week").format("YYYY-MM-DD"),
        end_date: moment().add(1, "week").add(1, "day").format("YYYY-MM-DD"),
        treatment_dates: [],
        pms_items: [
            {
                adults: 2,
                is_completed: false,
                sub_total: 0,
            },
        ],
        treatment_items: [],
        retail_items: [],
        dining_items: [],
        gift_card_items: [],
        promo_items: [],
        cart_items: 0,
        insurance_added: false,
        early_spa_added: false,
        insurance_cost: 0,
        early_spa_access_cost: 0,
        total: 0,
        insurance_total: 0,
        discount_total: 0,
        gift_card_total: 0,
        guests: 2,
        status: "pending",
        itinerary_ref: null,
    },
    itinary: [],
    webfolio: [],
    orderReference: null,
    error: {
        hasError: false,
        message: "",
    },
});

export const useBookingStore = defineStore(
    "booking",
    () => {
        const state = reactive<BookingStoreState>(initialState());
        const systemStore = useSystemStore();
        const resetStore = useResetStore();

        async function createBooking(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post(
                    "api/booking/start",
                    state.booking
                );

                state.booking = response.data.data;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function getBooking(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post(
                    "api/booking/get", {
                        booking_id: state.booking.id
                    }
                );

                state.booking = response.data.data;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function updateBooking(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post(
                    "api/booking/update",
                    state.booking
                );

                state.booking = response.data.data;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function resetBooking(): Promise<boolean> {
            state.isLoading = true;

            try {
                await api.post("api/booking/reset", {
                    booking_id: state.booking.id,
                    webfolio_id: systemStore.state.webfolioId,
                    session_id: systemStore.state.sessionId,
                });

                resetStore.all();
                systemStore.initaliseSystem();

                state.isLoading = false;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function fetchWebfolio(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/booking/cart", {
                    booking_id: state.booking.id,
                    webfolio_id: systemStore.state.webfolioId,
                    session_id: systemStore.state.sessionId,
                });

                state.webfolio = response.data.data;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function addFlexInsurance(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post(
                    "api/booking/add-insurance",
                    {
                        booking_id: state.booking.id,
                        webfolio_id: systemStore.state.webfolioId,
                        session_id: systemStore.state.sessionId,
                    }
                );

                state.booking = response.data.data;

                push.success({  
                    title: "Booking Update",
                    message: "Flex booking insurance has been added to your booking."
                });

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function addEarlyAccess(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post(
                    "api/booking/add-early-spa",
                    {
                        booking_id: state.booking.id,
                        webfolio_id: systemStore.state.webfolioId,
                        session_id: systemStore.state.sessionId,
                    }
                );

                state.booking = response.data.data;

                push.success({  
                    title: "Booking Update",
                    message: "Spa access on arrival has been added to your booking."
                });

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function getBookingItinary(
            itinaryRef: string | null,
            guestItinaryRef: string | null
        ): Promise<boolean> {
            state.isLoading = true;

            try {
                if (!itinaryRef) {
                    state.error.hasError = true;
                    state.error.message = "Itinary reference is required.";

                    return false;
                }

                const response = await api.post("api/booking/itinerary", {
                    itinerary_ref: itinaryRef,
                    guest_itinerary_ref: guestItinaryRef,
                });

                state.itinary = response.data.data;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function updateBookingGuests(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/booking/guests", {
                    booking_id: state.booking.id,
                    pms_items: state.booking.pms_items,
                });

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function getBookingAnalytics(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/booking/analytics", {
                    booking_id: state.booking.id,
                });

                return response.data.data;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        const getVenueId = (): number | null => {
            return state.booking.venue_id === 100 &&
                (state.booking.experience === "spa_day" ||
                    state.booking.experience === "spa_evening")
                ? 200
                : state.booking.venue_id;
        };

        const isPmsBooking = (): boolean => {
            return state.booking.experience === "stay" ||
                state.booking.experience === "spa_day" ||
                state.booking.experience === "spa_evening"
                ? true
                : false;
        };

        const isDayBooking = (): boolean => {
            return (
                state.booking.experience === "spa_day" ||
                state.booking.experience === "spa_evening" ||
                state.booking.experience === "dining" ||
                state.booking.experience === "treatments" ||
                state.booking.experience === "gift_cards"
            );
        };

        const isRetailOnly = (): boolean => {
            return state.booking.experience === "gift_cards";
        };

        const resetPmsItems = (): void => {
            state.booking.pms_items = [
                {
                    adults: 2,
                    is_completed: false,
                    sub_total: 0,
                },
            ];
        };

        const $reset = () => {
            Object.assign(state, initialState());
        };

        return {
            state,
            initialState,
            createBooking,
            getBooking,
            updateBooking,
            resetBooking,
            addFlexInsurance,
            addEarlyAccess,
            fetchWebfolio,
            getBookingItinary,
            updateBookingGuests,
            getBookingAnalytics,
            getVenueId,
            isPmsBooking,
            isDayBooking,
            isRetailOnly,
            resetPmsItems,
            $reset,
        };
    },
    {
        persist: {
            storage: sessionStorage,
        },
    }
);
