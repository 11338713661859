import { baseGate } from "@m-media/vue3-gate-keeper";
import { useBookingStore } from "@/stores/booking/booking";
import { useBookingStepsStore } from "@/stores/booking/bookingSteps";

/** A middleware that checks if the user is authenticated */
export default class extends baseGate {
    async handle() {
        const bookingStore = useBookingStore();
        const bookingStepsStore = useBookingStepsStore();

        if(bookingStore.isPmsBooking()) {
            bookingStore.state.booking.pms_items.forEach((item) => {
                if (item.inclusives_completed === false) {
                    return bookingStepsStore.goToStep(2);
                }

                if (bookingStore.state.booking.experience === "stay" && item.guests) {
                    item.guests.forEach((guest) => {
                        if (guest.inclusives_completed === false) {
                            return bookingStepsStore.goToStep(2);
                        }
                    });
                }
            });
        }
    }
}
