<template>
    <header
        ref="header"
        class="fixed top-0 left-0 right-0 z-10 transition-transform duration-300 bg-ivory-900 w-screen flex items-center justify-between py-5 px-4 | md:py-6 | lg:px-[72px]"
        :class="['header', { 'header-hidden': isScrollingDown }]"
    >
        <div
            class="flex w-4/12 items-center | md:hidden"
            v-if="route.path === '/'"
        >
            <DynamicButton
                variant="text"
                size="none"
                color="primary"
                @click="handleBackClick"
                aria-label="Go back"
            >
                <template #icon-before>
                    <ExitIcon />
                </template>
                <span class="flex text-xs font-bold">
                    {{ $t("common.exit") }}
                </span>
            </DynamicButton>
        </div>

        <div class="hidden | md:flex md:w-4/12 md:items-center">
            <DynamicButton
                variant="text"
                size="none"
                color="primary"
                @click="handleBackClick"
                aria-label="Go back"
            >
                <template #icon-before>
                    <ExitIcon />
                </template>
                <span class="text-xs font-bold md:flex">
                    {{ $t("headerBar.backText") }} {{ venueLinkText }}
                </span>
            </DynamicButton>
        </div>

        <h1
            class="flex w-4/12 items-center logo cursor-pointer"
            @click="navigateHome"
        >
            <component :is="currentLogoComponent" />
            <span class="sr-only">{{ $t("headerBar.logoText") }}</span>
        </h1>

        <div
            class="flex items-center justify-end w-4/12 gap-3 md:gap-4 md:relative"
        >
            <DynamicButton
                v-if="!userStore.state.isAuthenticated"
                variant="text"
                size="none"
                color="transparent"
                href="/login"
                aria-label="Login"
            >
                <ProfileIcon />
            </DynamicButton>
            <DynamicButton
                v-if="userStore.state.isAuthenticated"
                variant="text"
                size="none"
                color="transparent"
                additionalClasses="overflow-visible"
                @click.stop="toggleUserPopover"
                aria-label="User Menu"
                :aria-expanded="isUserPopoverVisible"
            >
                <template #icon-before>
                    <ProfileIcon />
                </template>

                <div class="hidden md:block">
                    {{ userInitials }}
                </div>

                <PopOver
                    ref="userPopover"
                    label=""
                    :hideIcon="true"
                    variant="headermenu"
                    v-if="userStore.state.isAuthenticated"
                    class="-mt-10"
                >
                    <nav
                        role="profileMenu"
                        aria-label="Profile Menu"
                        class="flex flex-col gap-2 items-start content-start"
                    >
                        <DynamicButton
                            variant="text"
                            size="none"
                            color="transparent"
                            href="/my-account/profile"
                            aria-label="Profile"
                        >
                            Profile
                        </DynamicButton>
                        <DynamicButton
                            variant="text"
                            size="none"
                            color="transparent"
                            @click="logout"
                            aria-label="Logout"
                        >
                            Logout
                        </DynamicButton>
                    </nav>
                </PopOver>
            </DynamicButton>

            <DynamicButton
                variant="text"
                size="none"
                color="transparent"
                :href="phoneHref"
                @click="handleTelLink"
                aria-label="Call us"
            >
                <template #icon-before>
                    <PhoneIcon />
                </template>
            </DynamicButton>
            <DynamicButton
                @click="toggleCart()"
                variant="text"
                size="none"
                color="transparent"
                class="overflow-visible text-primary"
                aria-label="Shopping cart"
                :aria-expanded="isCartVisible"
                v-if="
                    bookingStore.state.booking.status !== 'complete' &&
                    checkoutStore.state.transactionId === null
                "
            >
                <template #icon-before>
                    <CartIcon />
                </template>
                <template
                    #icon-after
                    v-if="bookingStore.state.booking.cart_items > 0"
                >
                    <span class="indicator">
                        {{ bookingStore.state.booking.cart_items }}
                    </span>
                </template>
            </DynamicButton>
        </div>
    </header>

    <PopupModel
        :visible="isExitPopupVisible"
        @update:visible="isExitPopupVisible = $event"
        :title="$t('headerBar.exitPopupTitle')"
    >
        <template #buttons>
            <DynamicButton
                variant="solid"
                color="primary"
                additionalClasses="w-full"
                @click="isExitPopupVisible = false"
                aria-label="Continue"
            >
                {{ $t("headerBar.exitPopupContinue") }}
            </DynamicButton>
            <DynamicButton
                variant="outline"
                color="primary"
                :href="venueLink"
                additionalClasses="w-full"
                aria-label="Leave"
            >
                {{ $t("headerBar.exitPopupLeave") }}
            </DynamicButton>
        </template>
    </PopupModel>

    <ShoppingCart
        :visible="isCartVisible"
        @update:visible="isCartVisible = $event"
    />

    <a class="sr-only" href="#main">Skip to main content</a>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed, nextTick } from "vue";
import DynamicButton from "@/components/forms/DynamicButton.vue";
import ShoppingCart from "@/components/global/ShoppingCart.vue";
import PopupModel from "@/components/global/PopupModel.vue";
import ExitIcon from "@/components/icons/ExitIcon.vue";
import ProfileIcon from "@/components/icons/ProfileIcon.vue";
import PhoneIcon from "@/components/icons/PhoneIcon.vue";
import CartIcon from "@/components/icons/CartIcon.vue";
import PopOver from "@/components/global/PopOver.vue";
import router from "@/router";
import DefaultLogo from "@/components/logos/DefaultLogo.vue";
import HoarcrosshallLogo from "@/components/logos/HoarcrosshallLogo.vue";
import EdenHallLogo from "@/components/logos/EdenHallLogo.vue";
import { useRoute } from "vue-router";
import { useUserStore } from "@/stores/auth/user";
import { useBookingStore } from "@/stores/booking/booking";
import { useProfileStore } from "@/stores/auth/profile";
import { useCheckoutStore } from "@/stores/checkout/checkout";

type PopOverInstance = {
    togglePopover: () => void;
};

const isScrollingDown = ref(false);
let lastScrollPosition = 0;
let ticking = false;
const headerHeight = ref(0);

const route = useRoute();
const bookingStore = useBookingStore();
const profileStore = useProfileStore();
const userStore = useUserStore();
const checkoutStore = useCheckoutStore();

const phoneNumber = "0123456789";
const isMobile = ref(
    /Mobi|Android|iPhone|iPad|Windows Phone/i.test(navigator.userAgent)
);
const phoneHref = ref(isMobile.value ? `tel:${phoneNumber}` : "#");

const handleTelLink = (event: Event) => {
    if (!isMobile.value) {
        event.preventDefault();
        window.open("https://baronseden.com/contact-us", "_blank");
    }
};

const handleScroll = () => {
    const currentScroll = window.scrollY;

    if (!ticking && Math.abs(currentScroll - lastScrollPosition) > 50) {
        window.requestAnimationFrame(() => {
            isScrollingDown.value = currentScroll > lastScrollPosition;
            lastScrollPosition = currentScroll <= 0 ? 0 : currentScroll;

            ticking = false;
        });

        ticking = true;
    }
};

const updateBodyPadding = () => {
    const headerElement = document.querySelector("header");

    if (headerElement) {
        headerHeight.value = headerElement.offsetHeight;
        (
            document.querySelector(".main-container") as HTMLElement
        ).style.paddingTop = `${headerHeight.value}px`;
    }
};

const venue = computed(() =>
    bookingStore.state.booking.venue_id === 100
        ? "Hoar Cross Hall"
        : "Eden Hall"
);

const venueLink = computed(() =>
    route.path.startsWith("/my-account")
        ? "/"
        : bookingStore.state.booking.venue_id === 100
        ? "https://baronseden.com/hoar-cross-hall"
        : "https://baronseden.com/eden-hall"
);

const venueLinkText = computed(() =>
    route.path.startsWith("/my-account") ? "Back" : venue.value
);

const userPopover = ref<PopOverInstance | null>(null);
const isCartVisible = ref(false);
const isExitPopupVisible = ref(false);
const countdownVisible = ref(false);
const loading = ref<boolean>(false);
const isUserPopoverVisible = ref(false);

interface Props {
    theme: string;
}

const props = defineProps<Props>();

const logoComponents: { [key: string]: any } = {
    default: DefaultLogo,
    hoarcrosshall: HoarcrosshallLogo,
    edenhall: EdenHallLogo,
};

const currentLogoComponent = computed(
    () => logoComponents[props.theme] || DefaultLogo
);

const navigateHome = () => {
    router.push("/");
};

const toggleUserPopover = () => {
    if (userPopover.value) {
        userPopover.value.togglePopover();
    }
};

const toggleCart = () => {
    isCartVisible.value = !isCartVisible.value;
};

const showExitPopup = () => {
    isExitPopupVisible.value = true;
};

// Handle booking start, extend, and cancel
const handleBookingStart = () => {
    countdownVisible.value = true;
};
const handleExtendBooking = () => {
    console.log("Extend booking");
};
const handleCancelBooking = () => {
    countdownVisible.value = false;
    console.log("Cancel booking");
};

const logout = async () => {
    loading.value = true;

    try {
        const response = await userStore.logout();

        if (response === true) {
            router.push("/login");
        }
    } catch (error: any) {
        console.error(error);
    } finally {
        loading.value = false;
    }
};

const handleBackClick = () => {
    if (route.path.startsWith("/my-account")) {
        navigateHome();
    } else {
        showExitPopup();
    }
};

const handleResize = () => {
    updateBodyPadding();
};

const userInitials = computed(() => {
    const firstName = profileStore.state.profile?.first_name || "";
    const lastName = profileStore.state.profile?.last_name || "";
    return `${firstName.charAt(0).toUpperCase()}${lastName
        .charAt(0)
        .toUpperCase()}`;
});

onMounted(() => {
    handleBookingStart();

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    nextTick(() => {
        updateBodyPadding();
    });
});

onUnmounted(() => {
    window.removeEventListener("scroll", handleScroll);
    window.removeEventListener("resize", handleResize);
});
</script>

<style scoped lang="scss">
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.header {
    will-change: transform;
}

.header-hidden {
    transform: translateY(-100%);
}

:deep(a) {
    svg {
        @apply size-5 md:size-6;
    }
}

:deep(button) {
    svg {
        @apply size-5 md:size-6;
    }
}

:deep(.logo) {
    svg {
        @apply h-7 ml-auto mr-auto md:h-12;
    }
}

:deep(.indicator) {
    @apply bg-primary text-ivory-100 rounded-full w-4 h-4 flex items-center justify-center text-xxs;
    @apply absolute -top-1 -right-1;
}
</style>
