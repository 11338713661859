// src/plugins/i18n.ts
import { createI18n } from "vue-i18n";
import en from "@/locales/en";

const messages = {
    en,
};

const i18n = createI18n({
    locale: "en",
    messages,
});

export default i18n;
