<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  :stroke="color">
        <path d="M14.2013 10.0111C14.62 10.3911 14.8541 10.8158 14.8541 11.2639C14.8541 12.8862 11.7854 14.2014 7.99992 14.2014C4.21446 14.2014 1.14575 12.8862 1.14575 11.2639C1.14575 10.8158 1.37986 10.3911 1.79853 10.0111" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.2639 7.05868C11.2639 4.71116 9.21133 2.77892 8.36161 2.07959C8.14861 1.9043 7.85134 1.9043 7.63833 2.07959C6.78861 2.77892 4.73608 4.71116 4.73608 7.05868C4.73608 9.02057 6.19739 10.2846 7.99997 10.2846C9.80255 10.2846 11.2639 9.02057 11.2639 7.05868Z" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.89808 3.91005C4.64579 3.60521 3.40225 3.6605 2.75679 3.72313C2.48223 3.7498 2.27202 3.96002 2.24536 4.23458C2.13903 5.32991 2.05396 8.14759 3.71391 9.80754C4.89505 10.9887 6.46749 11.0631 7.69543 10.2724" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.1015 3.90999C11.3537 3.60521 12.5971 3.6605 13.2426 3.72313C13.5171 3.7498 13.7273 3.96003 13.754 4.23459C13.8603 5.32991 13.9454 8.1476 12.2854 9.80754C11.1043 10.9886 9.53186 11.0631 8.30396 10.2724" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script setup lang="ts">
interface Props {
    color?: string;
}

const props = defineProps<Props>();

const color = props.color || "currentColor";
</script>

<style scoped lang="scss"></style>
