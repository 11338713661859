import { reactive } from "vue";
import { defineStore } from "pinia";
import type { Rate, Room, Error } from "@/types/index";
import api from "@/api/axios";
import { useBookingStore } from "@/stores/booking/booking";
import { useSystemStore } from "@/stores/system/system";

interface RatesState {
    isLoading: boolean;
    ratesMap: Record<number, Rate[]>;
    error: Error;
}

const initialState = (): RatesState => ({
    isLoading: false,
    ratesMap: {},
    error: {
        hasError: false,
        message: "",
    },
});

export const useRatesStore = defineStore(
    "rates",
    () => {
        const state = reactive<RatesState>(initialState());

        const getRateRooms = (
            roomIndex: number,
            rateCode: string
        ): Room[] | [] => {
            const rate = state.ratesMap[roomIndex]?.find(
                (rate) => rate.rate_code === rateCode
            );

            return rate?.rooms || [];
        };

        const fetchRates = async (
            roomIndex: number,
            adults: number,
            promoCode: string | null = null
        ): Promise<Rate[] | void> => {
            state.isLoading = true;
            state.error = { hasError: false, message: "" };

            try {
                const bookingStore = useBookingStore();
                const systemStore = useSystemStore();

                const response = await api.post("api/pms/rates", {
                    booking_id: bookingStore.state.booking.id,
                    adults: adults,
                    venue_id: bookingStore.getVenueId(),
                    start_date: bookingStore.state.booking.start_date,
                    end_date: bookingStore.state.booking.end_date,
                    promo_code: promoCode,
                });

                const ratesData: Rate[] = response.data.data;
                state.ratesMap[roomIndex] = ratesData;

                await Promise.all(
                    state.ratesMap[roomIndex].map(async (rate) => {
                        const roomsResponse = await api.post("api/pms/rooms", {
                            venue_id: bookingStore.getVenueId(),
                            webfolio_id: systemStore.state.webfolioId,
                            start_date: bookingStore.state.booking.start_date,
                            end_date: bookingStore.state.booking.end_date,
                            rate_code: rate.rate_code,
                            adults: adults,
                        });

                        const roomsData: Room[] = roomsResponse.data.data;
                        rate.rooms = roomsData;

                        const cheapestRoomPrice = rate.rooms?.reduce(
                            (minPrice, room) => {
                                return room.amount < minPrice
                                    ? room.amount
                                    : minPrice;
                            },
                            Infinity
                        );

                        rate.lowest_room_price = cheapestRoomPrice;
                    })
                );

                return state.ratesMap[roomIndex];
            } catch (error: any) {
                state.error = { hasError: true, message: error.message };
                return;
            } finally {
                state.isLoading = false;
            }
        };

        const $reset = () => {
            Object.assign(state, initialState());
        };

        return {
            state,
            fetchRates,
            getRateRooms,
            $reset,
        };
    },
    {
        persist: {
            storage: sessionStorage,
        },
    }
);
